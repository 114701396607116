import type {AdCreativeConfig, DeeplinkAction, QuarkUrlAction, QrAction, OpenUrlAction} from 'features/adoppler';

type ExtAction = AdCreativeConfig['action'];
/**
 * Checks if the action is of type 'qr'.
 *
 * @param {ExtAction} action - The action object to check.
 * @return {boolean} - True if the action is of type 'qr', otherwise false.
 */
function isQrParams(action: ExtAction): action is QrAction {
  return action?.type === 'qr';
}

/**
 * Checks if the action is of type 'open_url'.
 *
 * @param {ExtAction} action - The action object to check.
 * @return {boolean} - True if the action is of type 'open_url', otherwise false.
 */
function isOpenUrlParams(action: ExtAction): action is OpenUrlAction {
  return action?.type === 'open_url';
}

/**
 * Checks if the action is of type 'quark_url'.
 *
 * @param {ExtAction} action - The action object to check.
 * @return {boolean} - True if the action is of type 'quark_url', otherwise false.
 */
function isQuarkUrlParams(action: ExtAction): action is QuarkUrlAction {
  return action?.type === 'quark_url';
}

/**
 * Checks if the action is of type 'deeplink'.
 *
 * @param {ExtAction} action - The action object to check.
 * @return {boolean} - True if the action is of type 'deeplink', otherwise false.
 */
function isDeeplinkParams(action: ExtAction): action is DeeplinkAction {
  return action?.type === 'deeplink';
}

export const typeGuard = {
  isQrParams,
  isOpenUrlParams,
  isDeeplinkParams,
  isQuarkUrlParams,
};
