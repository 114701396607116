import axios from 'axios';

import {meteEnvConfig} from 'config';

const stagingUrl = `https://service-bootstrap.ads.teevee.info`;
const productionUrl = `https://service-bootstrap.ads.teevee.com`;

const endpoints = {
  development: stagingUrl,
  staging: stagingUrl,
  production: productionUrl,
};

const baseUrl = endpoints[meteEnvConfig.environment];

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    // eslint-disable-next-line max-len
    Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IkdhYnJpZWwiLCJwYXJ0bmVySWQiOiJUZWxseSJ9.Yc8yst9w06hs4GrOew2w94I3B3bCwJA-2YDsjo5etCM',
  },
});

export {
  baseUrl,
  instance,
};
