import {logger as baseLogger} from 'shared/utils/logger';

const logger = baseLogger.child({tag: '[Rectangle Coordinates]'});
/**
 * Randomly selects x and y coordinates within a rectangle.
 * @param {DOMRect} adCoordinates - The coordinates of the ad.
 * @return {{x: number, y: number}} - The randomly selected x and y coordinates.
 */
function getRandomCoordinatesInRectangle({x, y, width, height}: DOMRect): {x: number, y: number} {
  // Generate random x, y within rectangle with 20px margin to avoid clicking the close button on Google ads.
  const margin = 20;
  const minX = x + margin;
  const maxX = x + width - margin;
  const minY = y + margin;
  const maxY = y + height - margin;

  const randomX = Math.floor((Math.random() * (maxX - minX + 1)) + minX);
  const randomY = Math.floor((Math.random() * (maxY - minY + 1)) + minY);

  logger.debug('Rectangle coordinates', {x, y, width, height, minX, maxX, minY, maxY, randomX, randomY});

  return {x: randomX, y: randomY};
}

export default getRandomCoordinatesInRectangle;
