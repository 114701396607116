import React from 'react';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

import {logger as baseLogger} from 'shared/utils/logger';

const logger = baseLogger.child({tag: '[Adoppler QueryClient]'});
const CACHE_TIME = 2 * 60 * 1000;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Cache-First approach.
      gcTime: CACHE_TIME, // cache for 2 minutes
      refetchOnWindowFocus: false,
      throwOnError: false,
    },
    mutations: {
      onError: (error: Error) => {
        logger.error(`In QueryClient on 'mutation' operation provider error detected: ${error.message}`);
      },
    },
  },
});

const withReactQuery = (FC: React.FC) => function() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom" />
      <FC />
    </QueryClientProvider>
  );
};

export default withReactQuery;
