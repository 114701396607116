import React from 'react';

import AppContextProvider from 'context/app-context/AppContextProvider';

const withAppContext = (FC: React.FC) =>
  function() {
    return (
      <AppContextProvider>
        <FC />
      </AppContextProvider>
    );
  };

export default withAppContext;
