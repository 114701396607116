import {endpoints} from 'shared/api/ad/adoppler/constants';

import type {AdConfig, AdUnitType} from 'types';

import type {AdTypes} from 'features/adoppler';

 type AdUnitsConfig = {
    [key in AdUnitType]: AdConfig;
  };

const FETCH_INTERVAL = 30 * 1000;
const defaultCacheMaxAge = 29 * 60 * 1000; // 29 min
const defaultCacheRetryInterval = 15 * 1000; // 15 sec

const adUnitsConfig: AdUnitsConfig = {
  EVE_FULLSCREEN_UNIT: {
    /** Which one strategy from SDK should be used for rotation ads in the ads unit */
    strategy: 'gabriel',
    /** Physical slot configuration */
    slot: {
      width: 1920,
      height: 360,
    },
    providers: {
      /** Ad SDK Google Ads Native configuration  */
      googleNativeSdk: {},
      /** Gabriel Application configuration */
      gabriel: {
        adoppler: {
          apiEndpoints: {
            acr: endpoints.stagingAcr,
            regular: endpoints.staging,
          },
          /** what strategy chosen for rendering */
          strategy: 'adoppler-multibid-strategy',
          /** specific strategy configuration. */
          config: {
            fetchInterval: FETCH_INTERVAL,
            weightConfig: {
              500: 25,
              Video: 70,
            },
            vastPriority: {
              maxResolution: {width: 1280, height: 720},
              maxBitrate: 5000,
            },
            elementalEnv: 'production',
          },
          /** gabriel template configuration. */
          template: 'eve-screen-fullscreen',
          /** allowed ad types for rotation. */
          adTypes: ['MultiBid'] as AdTypes[],
          /** specific worker caching strategy. */
          cache: {
            'adoppler-500': {
              retryInterval: defaultCacheRetryInterval,
              capacity: 2,
              maxAge: defaultCacheMaxAge,
            },
            'adoppler-video': {
              retryInterval: defaultCacheRetryInterval,
              capacity: 5,
              maxAge: defaultCacheMaxAge,
            },
            default: {
              retryInterval: defaultCacheRetryInterval,
              capacity: 2,
              maxAge: defaultCacheMaxAge,
            },
          },
        },
        googleTagManager: {
          /** @example '/22982538162/EVE_FULLSCREEN_UNIT/staging'
             * The first parameter is static - it is the ID of our ad network.
             * The second parameter is the name of the adUnit ad slot (AdUnitType)
             * The names of our environments. Right now it's staging, development
             * and production */
          scriptURLs: [
            'https://a.pub.network/freetelly-com/test/46/pubfig.min.js?bypass=true',
            'https://a.pub.network/freetelly-com/pubfig.min.js',
          ],
          slotPlacementId: 'native-ad-fs',
          placementName: 'freetelly_300x250_test',
          slotSizes: [[300, 250], [514, 290]],
          slotId: '22982538162',
          eagerLoading: false,
        },
      },
    },
  },
  ADAM_SCREENSAVER_VIDEO_UNIT: {
    /** Which one strategy from SDK should be used for rotation ads in the ads unit */
    strategy: 'gabriel',
    /** Physical slot configuration */
    slot: {
      width: 1220,
      height: 686,
    },
    providers: {
      /** Ad SDK Google Ads Native configuration  */
      googleNativeSdk: {},
      /** Gabriel Application configuration */
      gabriel: {
        adoppler: {
          apiEndpoints: {
            acr: endpoints.stagingAcr,
            regular: endpoints.staging,
          },
          /** gabriel template configuration. */
          strategy: 'adoppler-barker-strategy',
          /** what strategy chosen for rendering */
          template: 'default',
          /** specific strategy configuration. */
          config: {
            showId: 's1',
            fetchInterval: FETCH_INTERVAL,
            vastPriority: {
              maxResolution: {width: 1920, height: 1080},
              maxBitrate: 5000,
            },
            elementalEnv: 'production',
          },
          /** specific worker caching strategy. */
          cache: {
            default: {
              retryInterval: defaultCacheRetryInterval,
              capacity: 2,
              maxAge: defaultCacheMaxAge,
            },
          },
          /** allowed ad types for rotation. */
          adTypes: ['AdPod'] as AdTypes[],
        },
        googleTagManager: {
          /** @example '/22982538162/EVE_FULLSCREEN_UNIT/staging'
             * The first parameter is static - it is the ID of our ad network.
             * The second parameter is the name of the adUnit ad slot (AdUnitType)
             * The names of our environments. Right now it's staging, development
             * and production */
          slotId: '',
        },
      },
    },

  },
  ADAM_SCREENSAVER_HOUSE_AD_UNIT_UP: {
    /** Which one strategy from SDK should be used for rotation ads in the ads unit */
    strategy: 'gabriel',
    /** Physical slot configuration */
    slot: {
      width: 520,
      height: 296,
    },
    providers: {
      /** Ad SDK Google Ads Native configuration  */
      googleNativeSdk: {},
      /** Gabriel Application configuration */
      gabriel: {
        adoppler: {
          apiEndpoints: {
            acr: endpoints.stagingAcr,
            regular: endpoints.staging,
          },
          /** gabriel template configuration. */
          strategy: 'adoppler-strategy',
          /** what strategy chosen for rendering */
          template: 'default',
          /** specific strategy configuration. */
          config: {
            fetchInterval: FETCH_INTERVAL,
          },
          /** specific worker caching strategy. */
          cache: {
            default: {
              retryInterval: defaultCacheRetryInterval,
              capacity: 2,
              maxAge: defaultCacheMaxAge,
            },
          },
          /** allowed ad types for rotation. */
          adTypes: [507],
          /** fallback type when main ad type is not accessible */
          fallbackAdType: 508,
        },
        googleTagManager: {
          /** @example '/22982538162/EVE_FULLSCREEN_UNIT/staging'
             * The first parameter is static - it is the ID of our ad network.
             * The second parameter is the name of the adUnit ad slot (AdUnitType)
             * The names of our environments. Right now it's staging, development
             * and production */
          slotId: '',
        },
      },
    },

  },
  ADAM_SCREENSAVER_HOUSE_AD_UNIT_DOWN: {
    /** Which one strategy from SDK should be used for rotation ads in the ads unit */
    strategy: 'gabriel',
    /** Physical slot configuration */
    slot: {
      width: 520,
      height: 296,
    },
    providers: {
      /** Ad SDK Google Ads Native configuration  */
      googleNativeSdk: {},
      /** Gabriel Application configuration */
      gabriel: {
        adoppler: {
          apiEndpoints: {
            acr: endpoints.stagingAcr,
            regular: endpoints.staging,
          },
          /** gabriel template configuration. */
          strategy: 'adoppler-strategy',
          /** what strategy chosen for rendering */
          template: 'default',
          /** specific strategy configuration. */
          config: {
            fetchInterval: FETCH_INTERVAL,
          },
          /** specific worker caching strategy. */
          cache: {
            default: {
              retryInterval: defaultCacheRetryInterval,
              capacity: 2,
              maxAge: defaultCacheMaxAge,
            },
          },
          /** allowed ad types for rotation. */
          adTypes: [508],
          /** fallback type when main ad type is not accessible */
          fallbackAdType: 507,
        },
        googleTagManager: {
          /** @example '/22982538162/EVE_FULLSCREEN_UNIT/staging'
             * The first parameter is static - it is the ID of our ad network.
             * The second parameter is the name of the adUnit ad slot (AdUnitType)
             * The names of our environments. Right now it's staging, development
             * and production */
          slotId: '',
        },
      },
    },
  },
  ADAM_SCREENSAVER_HOUSE_AD_UNIT_CENTER: {
    /** Which one strategy from SDK should be used for rotation ads in the ads unit */
    strategy: 'gabriel',
    /** Physical slot configuration */
    slot: {
      width: 946,
      height: 548,
    },
    providers: {
      /** Ad SDK Google Ads Native configuration  */
      googleNativeSdk: {},
      /** Gabriel Application configuration */
      gabriel: {
        adoppler: {
          apiEndpoints: {
            acr: endpoints.stagingAcr,
            regular: endpoints.staging,
          },
          /** gabriel template configuration. */
          strategy: 'adoppler-strategy',
          /** what strategy chosen for rendering */
          template: 'default',
          /** specific strategy configuration. */
          config: {
            // One minute interval.
            fetchInterval: 60 * 1000,
            elementalEnv: 'production',
          },
          /** specific worker caching strategy. */
          cache: {
            default: {
              retryInterval: defaultCacheRetryInterval,
              capacity: 2,
              maxAge: defaultCacheMaxAge,
            },
          },
          /** allowed ad types for rotation. */
          adTypes: [509],
        },
        googleTagManager: {
          /** @example '/22982538162/EVE_FULLSCREEN_UNIT/staging'
             * The first parameter is static - it is the ID of our ad network.
             * The second parameter is the name of the adUnit ad slot (AdUnitType)
             * The names of our environments. Right now it's staging, development
             * and production */
          slotId: '',
        },
      },
    },
  },
  '3P_SMART_SCREEN_AD_UNIT': {
    /** Which one strategy from SDK should be used for rotation ads in the ads unit */
    strategy: 'gabriel',
    /** Physical slot configuration */
    slot: {
      width: 1920,
      height: 360,
    },
    providers: {
      /** Ad SDK Google Ads Native configuration  */
      googleNativeSdk: {},
      /** Gabriel Application configuration */
      gabriel: {
        adoppler: {
          apiEndpoints: {
            acr: endpoints.stagingAcr,
            regular: endpoints.staging,
          },
          /** what strategy chosen for rendering */
          strategy: 'adoppler-strategy',
          /** specific strategy configuration. */
          config: {
            fetchInterval: FETCH_INTERVAL,
            elementalEnv: 'production',
          },
          /** gabriel template configuration. */
          template: 'eve-screen-fullscreen',
          /** allowed ad types for rotation. */
          adTypes: [500, 501, 506, 'Video', 'Google'] as AdTypes[],
          /** specific worker caching strategy. */
          cache: {
            'adoppler-500': {
              retryInterval: defaultCacheRetryInterval,
              capacity: 2,
              maxAge: defaultCacheMaxAge,
            },
            'adoppler-video': {
              retryInterval: defaultCacheRetryInterval,
              capacity: 5,
              maxAge: defaultCacheMaxAge,
            },
            default: {
              retryInterval: defaultCacheRetryInterval,
              capacity: 2,
              maxAge: defaultCacheMaxAge,
            },
          },
          /** fallback type when main ad type is not accessible */
          fallbackAdType: 506,
        },
        googleTagManager: {
          /** @example '/22982538162/EVE_FULLSCREEN_UNIT/staging'
         * The first parameter is static - it is the ID of our ad network.
         * The second parameter is the name of the adUnit ad slot (AdUnitType)
         * The names of our environments. Right now it's staging, development
         * and production */
          slotId: '22982538162',
        },
      },
    },
  },
};

export {adUnitsConfig};
