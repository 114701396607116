import type {DeviceInfo, ParsedResponse, AdConfig} from 'types';

/**
 * Basic strategy class
 */
export abstract class AbstractStrategy {
  deviceProps: DeviceInfo | null = null;
  adUnitConfig: AdConfig | undefined;

  /**
   * Check that provided bid can be displayed
   * @param {ParsedResponse | undefined} response
   * @protected
   * @return {boolean}
   */
  protected isValid(response: ParsedResponse | undefined): boolean {
    if (!response) {
      return false;
    }
    const showQAB = this.adUnitConfig?.features?.showQAB?.enable;

    // check for skipQAB validation
    // @link: https://teevee.atlassian.net/browse/TV1-11772
    return !(!showQAB && response.adBid?.ext?.creative_type?.ad_conversion_trigger);
  }
}
