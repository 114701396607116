import Joi from 'joi';
import {validate as uuidValidate} from 'uuid';

import {logger as baseLogger} from 'shared/utils/logger';

import type {DeviceInfo} from 'types';

const logger = baseLogger.child({tag: '[Device Properties Validator]'});

const deviceInfoSchema = Joi.object<DeviceInfo>({
  ad_info: Joi.object({
    ifa: Joi.string().required(),
    ifa_type: Joi.string().valid('dpid', 'sessionid').required(),
    lmt: Joi.number().valid(0, 1).required(),
  }).custom((adInfo, helpers) => {
    if (adInfo.lmt === 0) {
      if (!uuidValidate(adInfo.ifa)) {
        return helpers.error('uuid.invalid');
      }
    } else if (adInfo.lmt === 1) {
      if (adInfo.ifa !== '00000000-0000-0000-0000-000000000000') {
        return helpers.error('ifa.invalid');
      }
    }
    return adInfo;
  }).required(),
  env: Joi.string().valid('production', 'staging', 'development').required(),
  ip_address: Joi.string().ip({
    version: [
      'ipv4',
      'ipv6',
    ],
  }),
  ecpServerVersion: Joi.string().allow(''),
  ecpClientVersion: Joi.string().allow(''),
  scaling_factor: Joi.number(),
  is_video_muted: Joi.boolean(),
  coppa: Joi.boolean(),
  versions: Joi.object({
    'ro.build.id': Joi.string(),
  }).unknown(),
}).unknown();

/**
   * Validates the properties of a device or ad information object using a schema.
   *
   * @param {DeviceInfo} deviceInfo - The device information or ad information object to validate.
   */
export function validateDeviceProperties(deviceInfo: DeviceInfo) {
  const {error} = deviceInfoSchema.validate(deviceInfo, {abortEarly: false});

  if (error) {
    logger.warn('Device Properties validation error', error);
  }
}
