import axios from 'axios';

import {meteEnvConfig} from 'config';

const stagingUrl = 'https://service-url.ads.teevee.info';
const productionUrl = 'https://service-url.ads.teevee.com';

const baseUrl = meteEnvConfig.environment === 'production' ? productionUrl : stagingUrl;
export const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 3000,
});
