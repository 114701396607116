import {compose} from 'recompose';

import withAppContext from './withAppContext';
import withContext from './withContext';
import withMuteContext from './withMuteContext';
import withOfflineHandling from './withOfflineHandling';
import withReactQuery from './withReactQuery';
import withSentry from './withSentry';
import withSentryErrorBoundary from './withSentryErrorBoundary';
import withSuspense from './withSuspense';
import withVersionCheck from './withVersionCheck';

// An order is required.
export const withProviders = compose(
  withReactQuery,
  withSentry,
  withMuteContext,
  withAppContext,
  withContext,
  withOfflineHandling,
  withVersionCheck,
);

export {
  withSuspense,
  withSentryErrorBoundary,
};
