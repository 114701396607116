import {useContext} from 'react';

import {AppContext, type AppContextType} from './AppContext';

const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppContext');
  }

  return context;
};

export default useAppContext;
