/* eslint-disable max-len */
import type {ReactNode} from 'react';
import {useLayoutEffect} from 'react';

import './styles.css';

interface AnimatedButtonProps {
    crid: string | undefined;
    buttonType: 'a_button' | 'b_button';
    screen: 'SMART' | 'THEATER' | 'none';
  }

/**
 * Component represents animated e
 * @param {AnimatedButtonProps} props
 * @constructor
 */
const AnimatedQuickActionComponent = (props: AnimatedButtonProps): ReactNode => {
  const adsRotation = getComputedStyle(document.body).getPropertyValue('--ads-rotation').replace(/\D/g, '') || '30';
  const animationClass = (props.screen === 'THEATER')? 'ads-animation' : 'ads-animation-eve';

  useLayoutEffect(() => {
    const adsAnimation = document.querySelector('.container > .adsense-section');
    if (adsAnimation) {
      setTimeout(() => {
        adsAnimation.classList.remove(animationClass);
        setTimeout(() => {
          adsAnimation.classList.add(animationClass);
        }, 100);
      }, parseInt(adsRotation, 10) * 0.4);
    }
  }, [props.crid, adsRotation, animationClass]);

  const aButton = (): ReactNode => {
    return <svg width="56" height="56" viewBox="0 0 56 56" id="button-a" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.34 55.22H15.88C7.12 55.22 0 48.1 0 39.34V15.88C0 7.13 7.12 0 15.88 0H39.34C48.1 0 55.22 7.12 55.22 15.88V39.34C55.22 48.09 48.1 55.22 39.34 55.22ZM15.88 3.47003C9.03 3.47003 3.46001 9.04001 3.46001 15.89V39.35C3.46001 46.2 9.03 51.77 15.88 51.77H39.34C46.19 51.77 51.76 46.2 51.76 39.35V15.89C51.76 9.04001 46.19 3.47003 39.34 3.47003H15.88Z"
        fill="#FFD354"/>
      <path
        d="M22.9306 37.03H20.3105L26.1906 18.21H29.0406L34.9205 37.03H32.3006L27.6806 21.6299H27.5506L22.9205 37.03H22.9306ZM23.3705 29.66H31.8506V32.05H23.3705V29.66Z"
        fill="#FFD354"/>
    </svg>;
  };

  const bButton = (): ReactNode => {
    return <svg width="31" height="30" viewBox="0 0 31 30" id="button-b" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 1.5C4.46243 1.5 2 3.96243 2 7V23C2 26.0376 4.46243 28.5 7.5 28.5H23.5C26.5376 28.5 29 26.0376 29 23V7C29 3.96243 26.5376 1.5 23.5 1.5H7.5Z"
        stroke="#00BAD8" strokeWidth="3"/>
      <path
        d="M15.8727 6.92C16.8167 6.92 17.6647 7.024 18.4167 7.232C19.1687 7.44 19.8007 7.744 20.3127 8.144C20.8407 8.528 21.2407 8.992 21.5127 9.536C21.8007 10.08 21.9447 10.704 21.9447 11.408C21.9447 12.224 21.7527 12.92 21.3687 13.496C20.9847 14.056 20.4247 14.496 19.6887 14.816C20.6007 15.12 21.3127 15.576 21.8247 16.184C22.3527 16.792 22.6167 17.568 22.6167 18.512C22.6167 19.2 22.4727 19.824 22.1847 20.384C21.9127 20.928 21.5127 21.4 20.9847 21.8C20.4727 22.184 19.8407 22.48 19.0887 22.688C18.3367 22.896 17.4887 23 16.5447 23H9.15266V6.92H15.8727ZM12.8487 13.76H15.6567C16.3927 13.76 16.9767 13.608 17.4087 13.304C17.8407 12.984 18.0567 12.536 18.0567 11.96C18.0567 11.384 17.8407 10.952 17.4087 10.664C16.9767 10.376 16.3927 10.232 15.6567 10.232H12.8487V13.76ZM12.8487 19.688H16.2087C17.0247 19.688 17.6487 19.536 18.0807 19.232C18.5127 18.912 18.7287 18.44 18.7287 17.816C18.7287 17.528 18.6727 17.272 18.5607 17.048C18.4647 16.824 18.3047 16.64 18.0807 16.496C17.8727 16.336 17.6007 16.224 17.2647 16.16C16.9447 16.08 16.5687 16.04 16.1367 16.04H12.8487V19.688Z"
        fill="#00BAD8"/>
    </svg>;
  };

  return (
    <div data-testid="qab" className="animated-button">
      <div className="container">
        <div className="adam-screen">&nbsp;</div>
        <div className="eve-screen">&nbsp;</div>
        <div className={`adsense-section ${animationClass}`}>&nbsp;</div>
      </div>
      <div className="button-control">
        {props.buttonType === 'a_button' ? aButton() : bButton()}
        <span>Press</span>
      </div>
    </div>
  );
};

export default AnimatedQuickActionComponent;
