import {memo, useEffect, useState} from 'react';

import {logger as baseLogger} from 'shared/utils/logger';
import QRCodeGenerator from 'shared/utils/qr-code-generator';

const logger = baseLogger.child({tag: 'QrCodeComponent'});

interface Props {
  url: string;
  exp?: number;
}

const QrCodeComponent = memo(({url, exp}: Props) => {
  const [qrCode, setQrCode] = useState<string | null>(null);

  useEffect(() => {
    const constructQr = async () => {
      try {
        const qrGenerator = new QRCodeGenerator();
        const dataUrl = await qrGenerator.getQRCodeAsBase64(url, exp ?? 9000);
        setQrCode(dataUrl);
      } catch (error) {
        logger.warn(error);
      }
    };
    constructQr();
  }, [url, exp]);

  if (!qrCode) return null;

  return (
    <div data-testid="qr-code-component" className='qr-container'>
      <img src={qrCode} alt="qr-code" className='qr-image'/>
      <div className="qr-text">
        <span>Scan</span>
      </div>
    </div>
  );
});

export default QrCodeComponent;
