import type {ReactNode} from 'react';

import {AdStrategyContext, type AdStrategyContextConfigType} from './AdStrategyContext';

// Custom hook to manage the domain state and actions
const useAdStrategy = (config: AdStrategyContextConfigType) => {
  return {
    startLoop: config.startLoop,
    stopLoop: config.stopLoop,
    googleCanBePlayed: config.googleCanBePlayed,
  };
};

interface AdStrategyContextProviderProps {
  children: ReactNode | null;
  config: AdStrategyContextConfigType
}

const AdStrategyContextProvider = ({children, config}: AdStrategyContextProviderProps): ReactNode | null => {
  const adStrategy = useAdStrategy(config);

  return (
    <AdStrategyContext.Provider value={{...adStrategy}}>
      {children}
    </AdStrategyContext.Provider>
  );
};

export default AdStrategyContextProvider;
