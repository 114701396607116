import Joi from 'joi';

export type OpenAPIDocument = {
  components: {
    schemas: {[key: string]: Schema}
  }
};

type Schema = {
  allOf?: Schema[];
  oneOf?: Schema[];
  type?: string;
  enum?: unknown[];
  format?: string;
  items?: Schema;
  $ref?: string;
  properties?: { [key: string]: Schema };
  required?: string[];
};

/**
 * Converts an OpenAPI schema to a Joi schema.
 *
 * @param {Schema | undefined} schema - The OpenAPI schema to convert.
 * @param {object} components - The components
 * @return {Joi.Schema | null} The corresponding Joi schema, or null if the schema is invalid.
 */
function convertToJoi(schema: Schema | undefined, components: { [n: string]: object }): Joi.Schema | null {
  if (!schema || typeof schema !== 'object') return null;
  if (schema.allOf) {
    return schema.allOf
      .map((subSchema) => convertToJoi(resolveRef(subSchema, components), components))
      .filter((s): s is Joi.AnySchema => s !== null)
      .reduce((prev, curr) => prev && curr && prev.concat(curr));
  }

  if (schema.oneOf) {
    return Joi.alternatives().try(
      ...schema.oneOf.map((subSchema) =>
        convertToJoi(resolveRef(subSchema, components), components),
      ),
    );
  }

  if (schema.type === 'object' ) {
    const joiSchema: { [key: string]: Joi.Schema | null } = {};
    if (schema.properties) {
      Object.keys(schema.properties).forEach((property) => {
        const propertySchema = resolveRef(schema.properties![property], components);

        let joiField = convertBasicTypeToJoi(propertySchema, components);
        // Handle required properties
        if (joiField && schema.required && schema.required.includes(property)) {
          joiField = joiField.required();
        }

        joiSchema[property] = joiField;
      });
    }

    return Joi.object(joiSchema);
  }

  return convertBasicTypeToJoi(schema, components);
}

/**
 * Converts basic types from the OpenAPI schema to Joi types.
 *
 * @param {Schema} propertySchema - The schema of the property being converted.
 * @param {object} components - The components (schemas) from the OpenAPI document.
 * @return {Joi.Schema | null} The corresponding Joi schema, or null if the type is not recognized.
 */
function convertBasicTypeToJoi(propertySchema: Schema, components: { [n: string]: object }): Joi.Schema | null {
  let joiField;
  switch (propertySchema.type) {
    case 'string':
      joiField = Joi.string();
      if (propertySchema.enum) {
        joiField = joiField.valid(...propertySchema.enum);
      }
      if (propertySchema.format === 'date-time') {
        joiField = joiField.isoDate();
      }
      break;
    case 'integer':
      joiField = Joi.number().integer();
      break;
    case 'number':
      joiField = Joi.number();
      break;
    case 'boolean':
      joiField = Joi.boolean();
      break;
    case 'array':
      joiField = Joi.array().items(convertToJoi(propertySchema.items, components));
      break;
    case 'object':
      joiField = convertToJoi(propertySchema, components);
      break;
    default:
      joiField = Joi.any();
  }
  return joiField;
}

/**
 * Resolves a `$ref` in the OpenAPI schema and returns the referenced schema.
 *
 * @param {Schema} schema - The schema potentially containing a `$ref`.
 * @param {object} components - The components (schemas) from the OpenAPI document.
 * @return {Schema} The resolved schema, or the original schema if no `$ref` is found.
 */
function resolveRef(schema: Schema, components: { [n: string]: object }): Schema {
  if (schema.$ref) {
    const [, pathInFile] = schema.$ref.split('#');
    const refPath = pathInFile.replace(/^\/components\/schemas\//, '');
    return components[refPath] || {};
  }
  return schema;
}

/**
 * Processes an OpenAPI document and converts all the schemas to Joi schemas.
 *
 * @param {OpenAPIDocument} openApiDoc - The OpenAPI document to process.
 * @return {object} An object mapping schema names to Joi schemas.
 */
export function generateJoiFromOpenAPI(openApiDoc: OpenAPIDocument) {
  const res: { [n: string]: Joi.Schema | null } = {};
  const components = openApiDoc.components.schemas;

  // Iterate over schemas
  Object.entries(components).forEach(([schemaName, schema]) => {
    const joiSchema = convertToJoi(schema, components);
    res[schemaName] = joiSchema;
  });
  return res;
}
