import {useQuery, useQueryClient, type UseQueryResult} from '@tanstack/react-query';

import {meteEnvConfig} from 'config';
import {useAppContext} from 'context';
import {parseVersion} from 'shared/utils';

import type {AdConfig} from 'types';

import {instance} from './constants';

/**
 * Fetch ad config from backend service API.
 *
 * @param {string} [ifa] - IFA ID to retrieve config for
 * @param {string} version - current application version
 * @return {Promise<AdConfig>} Promise resolving to ad config
 */
export const getConfig = async (ifa?: string, version?: string): Promise<AdConfig> => {
  const adUnit = meteEnvConfig.ads.adUnit;

  return instance
    .get(`/v1/adUnit/${adUnit}`, {params: {ifa, version, appVersion: __APP_VERSION__} })
    .then((res) => res.data);
};

/**
 * React hook to fetch ad config.
 *
 * @return {UseQueryResult<AdConfig>} Query result for ad config
 */
export const useGetConfig = (): UseQueryResult<AdConfig | undefined> => {
  const queryClient = useQueryClient();
  const {deviceProperties} = useAppContext();
  const buildId = deviceProperties?.versions?.['ro.build.id'];
  const version = parseVersion(buildId);
  const ifa = deviceProperties?.ad_info?.ifa;

  return useQuery({
    queryKey: ['config'],
    queryFn: () => getConfig(ifa, version ? version : undefined),
    enabled: !!deviceProperties,
    networkMode: 'online',
    initialData: () => queryClient.getQueryData(['config']),
  });
};
