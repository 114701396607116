import type {ReactNode} from 'react';

import {meteEnvConfig} from 'config';
import {AbstractStrategy} from 'entities/strategies/api/abstract';
import generateAdJSX from 'entities/strategies/ui/generateAdJSX';
import {AdTypes} from 'features/adoppler/enums';
import fetchAd from 'features/adoppler/service/fetch';
import {ADOPPLER_RENDER_EVENT, VIDEO_AD_FINISHED_EVENT} from 'shared/constants';
import {
  logger as baseLogger,
  subscribe,
  triggerCustomEvent,
  unsubscribe,
} from 'shared/utils';

import type {AdConfig, AdStrategyName, DeviceInfo, UseAdReturnType} from 'types';

import type {AdStrategy} from 'entities/strategies/model';

interface AdopplerStrategyType extends AdStrategy {
  determineAdType: () => AdTypes
}

const logger = baseLogger.child({tag: '[AdopplerStrategy]'});

/**
 * Represents the only adoppler strategy for selecting ad types.
 *
 * @implements {AdStrategy}
 */
class AdopplerStrategy extends AbstractStrategy implements AdopplerStrategyType {
  name: AdStrategyName;
  adTypes: AdTypes[];
  timeoutId: NodeJS.Timeout | undefined;
  videoFinishHandler: (() => void) | undefined;
  emptyHandler: (() => void) | undefined;
  awaitPromise: (() => void) | undefined;
  loopStart: boolean = false;
  googleCanBePlayed: boolean = false;

  /**
   * Creates an instance of the AdopplerRandomWeightedStrategy.
   *
   * @constructor
   * @param {AdTypes[] | undefined} adTypes - An array of ad types to be used by the strategy.
   * @param {DeviceInfo | null} deviceProps - DeviceProps we need to pass to handle validation
   * @param {AdConfig} adUnitConfig - application config object
   */
  constructor(adTypes: AdTypes[] | undefined, deviceProps: DeviceInfo | null, adUnitConfig: AdConfig) {
    super();
    this.name = 'adoppler-strategy';

    this.adTypes = (adTypes as AdTypes[])
        ?? (meteEnvConfig.ads.types?.length ? meteEnvConfig.ads.types : [
          AdTypes.HtmlBanner,
        ]);

    this.deviceProps = deviceProps;
    this.adUnitConfig = adUnitConfig;
  }
  /**
   * Determines the selected ad type based on the weighted configuration.
   *
   * @return {AdTypes} The selected ad type.
   */
  public determineAdType(): AdTypes {
    return this.adTypes[Math.floor(Math.random() * this.adTypes.length)];
  }

  /**
   * Run and managing fetching loop
   * @param {number} fetchInterval
   */
  public startLoop(fetchInterval: number): void {
    logger.info('Started strategy cycle');
    this.loopStart = true;
    (async () => {
      this.videoFinishHandler = () => {
        // we have active request, we should stop it before send new one on demand
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }

        if (this.awaitPromise) {
          this.awaitPromise();
        }
      };

      subscribe(VIDEO_AD_FINISHED_EVENT, this.videoFinishHandler);

      while (this.loopStart) {
        await this.run(fetchInterval);
      }
    })();
  }

  /**
   * Fetch response from the cache
   * @private
   * @return {Promise<UseAdReturnType>}
   */
  private async getResponse(): Promise<UseAdReturnType> {
    return await fetchAd(this.determineAdType());
  }

  /**
   * Unsubscribe from active videoHandler
   */
  private unsubscribeVideoHandler() {
    if (this.videoFinishHandler) {
      unsubscribe(VIDEO_AD_FINISHED_EVENT, this.videoFinishHandler);
      this.videoFinishHandler = undefined;
    }
  }

  /**
   * Run loop
   * @param {number} fetchInterval
   * @private
   */
  private async run(fetchInterval: number) {
    const response = await this.getResponse();
    triggerCustomEvent(ADOPPLER_RENDER_EVENT, response);

    await new Promise<void>((resolve) => {
      this.awaitPromise = resolve;

      this.timeoutId = setTimeout(() => {
        resolve();
      }, fetchInterval);
    });
  }

  /**
   * Stop running fetching loop
   */
  public stopLoop(): void {
    this.loopStart = false;
    if (this.timeoutId) {
      clearInterval(this.timeoutId);
    }

    this.unsubscribeVideoHandler();

    // show default logo
    triggerCustomEvent(ADOPPLER_RENDER_EVENT, {adType: AdTypes.DefaultTelly, adSettings: []});
  }

  /**
   * Retrieves an ad using the Adoppler random weighted strategy.
   *
   * @return {ReactNode | null} The JSX element representing the selected ad.
   */
  public getAd(): ReactNode | null {
    return generateAdJSX(this.name, this.startLoop.bind(this), this.stopLoop.bind(this), this.googleCanBePlayed);
  }
}

export {AdopplerStrategy};
