import type {ReactNode} from 'react';

import {Ad} from 'components/Ad';
import {AdStrategyContextProvider} from 'context';

/**
 * Generates a JSX element for displaying an ad using the provided ad domain information.
 *
 * @param {string} key - Rerender if key is changed
 * @param {Function} startLoop - A function to start adoppler loop
 * @param {Function} stopLoop - A function to stop adoppler loop
 * @param {boolean} googleCanBePlayed
 * @return {ReactNode | null} The JSX element representing the selected ad.
 */
export default function generateAdJSX(
  key: string,
  startLoop: (fetchInterval: number) => void,
  stopLoop: () => void,
  googleCanBePlayed: boolean,
): ReactNode | null {
  return (
    <AdStrategyContextProvider
      key={key}
      config={{
        startLoop, stopLoop, googleCanBePlayed,
      }}>
      <Ad />
    </AdStrategyContextProvider>
  );
}
