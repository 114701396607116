import {useContext} from 'react';

import {AdUnitConfigContext, type AdUnitConfigContextType} from './AdUnitConfigContext';

const useAdUnitConfigContext = (): AdUnitConfigContextType => {
  const context = useContext(AdUnitConfigContext);
  if (context === undefined) {
    throw new Error('useAdUnitConfigContext must be used within an AppProvider');
  }

  return context;
};

export default useAdUnitConfigContext;
