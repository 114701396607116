import type {SVGProps} from 'react';

/* eslint-disable max-len */
const TellyLogoSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 1101.75 476.19" {...props} fill="#ef453e">
      <polygon points="301.11 15.06 15.59 15.06 0 94.65 97.38 94.65 42.16 371.77 132.9 371.77 188.12 94.65 285.52 94.65 301.11 15.06"/>
      <path d="m466.08,139.61c-23.31-20.75-55.76-32.17-91.37-32.17-43.85,0-81.25,15.26-108.18,44.14-25.51,27.36-39.55,65.58-39.55,107.6,0,35.69,13.21,65.88,38.2,87.29,23.58,20.2,56.95,31.32,93.97,31.32,59.25,0,110.95-31.16,134.92-81.33l1.81-3.78h-84.68l-.79.86c-11.03,12.04-28.7,19.22-47.25,19.22-15.72,0-28.89-4.35-38.09-12.57-8.41-7.52-13.34-18.14-14.34-30.84h187.99l.53-1.95c3-10.98,4.61-28.47,4.61-39.35,0-35.86-13.07-66.45-37.78-88.45Zm-93.38,29.84c14.71,0,27.27,4.87,36.32,14.07,8.23,8.37,12.88,19.95,13.23,32.85h-106.13c2.75-13.62,9.1-25.1,18.48-33.34,10.12-8.89,23.29-13.59,38.1-13.59Z"/>
      <polygon points="588.41 0 514.06 371.77 601.78 371.77 676.14 0 588.41 0"/>
      <polygon points="718.44 0 644.08 371.77 731.81 371.77 806.16 0 718.44 0"/>
      <polygon points="1008.17 113.46 929.82 263.38 906.61 113.46 817.78 113.46 865.91 354.12 797.41 476.19 890.32 476.19 950.3 370.47 1101.75 113.46 1008.17 113.46"/>
    </svg>
  );
};

export default TellyLogoSvg;
