import React, {useEffect} from 'react';

const withVersionCheck = (FC: React.FC) => {
  return () => {
    useEffect(() => {
      const checkVersion = async () => {
        const storedVersion = localStorage.getItem('appVersion');
        const currentVersion = __APP_VERSION__;

        if (storedVersion !== currentVersion) {
          const cacheNames = await caches.keys();

          await Promise.all(
            cacheNames.map(async (cacheName) => {
              const cache = await caches.open(cacheName);
              await cache.keys().then((keys) => {
                return Promise.all(keys.map((key) => cache.delete(key)));
              });
            }),
          );
          localStorage.clear();
          localStorage.setItem('appVersion', currentVersion);
        }
      };

      checkVersion();
    }, []);

    return <FC />;
  };
};

export default withVersionCheck;
