export interface ConsentSettings {
  allowStorage: boolean;
}

export interface NonceManager {
  getNonce: () => string;
  sendPlaybackStart: () => void;
  sendPlaybackEnd: () => void;
  sendAdClick: () => void;
}

/**
 * Generates a nonce with sample arguments and logs it to the pino logger.
 * @param {boolean} isEveScreen
 *
 * @return {Promise<NonceManager>}
 */
export const generateNonce = (isEveScreen: boolean): Promise<NonceManager> | undefined => {
  const goog = window.goog;
  if (!goog) {
    return;
  }
  const consentSettings = new goog.pal.ConsentSettings();
  consentSettings.allowStorage = true;

  const nonceLoader = new goog.pal.NonceLoader(consentSettings);

  const request = new goog.pal.NonceRequest();
  request.adWillAutoPlay = true;
  request.adWillPlayMuted = isEveScreen;
  request.continuousPlayback = false;
  request.iconsSupported = true;
  request.playerType = 'Player';
  request.playerVersion = '1.0';
  // request.ppid = 'Sample PPID';
  // request.sessionId = 'Sample SID';
  // Player support for VPAID 2.0, OMID 1.0, and SIMID 1.1
  // request.supportedApiFrameworks = '2,7,9';

  // window location
  request.url = window.location.href;
  request.videoWidth = isEveScreen ? 512 : 1220;
  request.videoHeight = isEveScreen ? 290 : 686;

  return nonceLoader.loadNonceManager(request);
};
