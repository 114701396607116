import {meteEnvConfig, adUnitsConfig} from 'config';

import type {AdConfig} from 'types';

/**
 * Get default fallback ad config.
 *
 * @return {AdConfig} Default ad config
 */
const getDefaultAdConfig = (): AdConfig => {
  const adUnit = meteEnvConfig.ads.adUnit;
  return adUnitsConfig[adUnit];
};

export default getDefaultAdConfig;
