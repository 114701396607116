import {defaultExpandableAdConfig} from 'config';

import type {AdAnimationConfig, ExpandableAdConfig} from 'types';

import type {ResponseAdConfig} from 'features/adoppler';

/**
   * Represents an abstract AdConfigCreator class used to parse configurations into AdConfig objects.
   */
abstract class AdConfigCreator {
    /**
     * Parses the given configuration object and returns an AdConfig object.
     * @param {ResponseAdConfig} config - The configuration object to be parsed.
     * @returns {AdAnimationConfig} The parsed AdConfig object.
     */
    public abstract parseConfig(config: ResponseAdConfig): AdAnimationConfig;
}

/**
   * Represents an ExpandableAdConfigCreator class that implements the AdConfigCreator interface.
   */
class ExpandableAdConfigCreator implements AdConfigCreator {
  /**
     * Parses the given configuration object and returns an ExpandableAdConfig object.
     * @param {ResponseAdConfig} config - The configuration object to be parsed.
     * @return {ExpandableAdConfig} The parsed ExpandableAdConfig object.
     */
  public parseConfig(config: ResponseAdConfig): ExpandableAdConfig {
    const FULL_SCREEN_AD_DURATION =
        config.FULL_SCREEN_AD_DURATION !== undefined ? parseInt(config.FULL_SCREEN_AD_DURATION) : null;
    const INITIAL_WAITING_TIME_TO_EXPAND =
        config.INITIAL_WAITING_TIME_TO_EXPAND !== undefined ? parseInt(config.INITIAL_WAITING_TIME_TO_EXPAND) : null;

    return {
      INITIAL_WAITING_TIME_TO_EXPAND:
          INITIAL_WAITING_TIME_TO_EXPAND !== null
              ? INITIAL_WAITING_TIME_TO_EXPAND
              : defaultExpandableAdConfig.INITIAL_WAITING_TIME_TO_EXPAND,
      FULL_SCREEN_AD_DURATION:
          FULL_SCREEN_AD_DURATION !== null
              ? FULL_SCREEN_AD_DURATION
              : defaultExpandableAdConfig.FULL_SCREEN_AD_DURATION,
      DEFAULT_EXPAND_ANIMATION_DURATION: defaultExpandableAdConfig.DEFAULT_EXPAND_ANIMATION_DURATION,
    };
  }
}

/**
   * Represents the type of AdConfig.
   * @example
   * type AdConfigType = 'expandable' | "iab" | "video"
   */
export type AdConfigType = 'expandable';

/**
   * Represents a ConfigFactory class used to create different types of AdConfig objects based on the given type and configuration.
   */
class ConfigFactory {
  /**
       * Creates an AdConfig object based on the given type and configuration.
       * @param {AdConfigType} type - The type of AdConfig to be created.
       * @param {ResponseAdConfig} config - The configuration object used to create the AdConfig.
       * @return {AdAnimationConfig | undefined} The created AdConfig object or undefined if the type is not recognized.
       */
  createConfig(type: AdConfigType, config: ResponseAdConfig): AdAnimationConfig | undefined {
    let creator = null;

    if (type === 'expandable') {
      creator = new ExpandableAdConfigCreator();
    }

    if (creator !== null) {
      return creator.parseConfig(config);
    }
  }
}

export {AdConfigCreator, ExpandableAdConfigCreator, ConfigFactory};
