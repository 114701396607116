import {createContext} from 'react';

import type {AdConfig} from 'types';

export interface AdUnitConfigContextType {
  adUnitConfig: AdConfig;
}

export const AdUnitConfigContext = createContext<AdUnitConfigContextType>({
  adUnitConfig: {} as AdConfig,
});

AdUnitConfigContext.displayName = 'AdUnitConfigContext';
