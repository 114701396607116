/**
 * Checks if a given string is a valid IPv4 address.
 *
 * @param {string} address - The string to be checked.
 * @return {boolean} True if the input is a valid IPv4 address, false otherwise.
 */
export function isIPv4(address: string): boolean {
  const ipv4Regex = /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm;
  return ipv4Regex.test(address);
}

/**
 * Checks if a given string is a valid IPv6 address.
 *
 * @param {string} address - The string to be checked.
 * @return {boolean} True if the input is a valid IPv6 address, false otherwise.
 */
export function isIPv6(address: string): boolean {
  // eslint-disable-next-line max-len
  const ipv6Regex = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/gm;
  return ipv6Regex.test(address);
}

export type DeviceIpAddresses = {
  ip?: string;
  ipv6?: string;
}

/**
 * Transforms a given IP address into IPv4 or IPv6 format based on its type.
 *
 * @param {string} address - The IP address to be transformed.
 * @return {DeviceIpAddresses} An object with transformed IPv4 or IPv6 addresses.
 */
export function transformIPAddress(address?: string): DeviceIpAddresses {
  if (address && isIPv4(address)) {
    return {
      ip: address,
      ipv6: undefined,
    };
  }

  if (address && isIPv6(address)) {
    return {
      ip: undefined,
      ipv6: address,
    };
  }

  return {
    ip: undefined,
    ipv6: undefined,
  };
}
