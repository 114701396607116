import {videoProgress, type VideoProgressEnum} from 'shared/utils';
let lastProgressEvent: keyof typeof VideoProgressEnum | null = null;

/**
 * Function to log progress events based on played seconds of the video.
 * @param {number} playedSeconds - The number of seconds the video has been played.
 * @param {number} duration - The number of seconds of video duration.
 * @param {VoidFunction} analyticsSendCallback - The number of seconds of video duration.
 * @return {void}
 */
const sendBarkerAnalytics = (
  playedSeconds: number,
  duration: number,
  analyticsSendCallback: (eventType: keyof typeof VideoProgressEnum) => void,
): void => {
  const currentEvent = videoProgress(playedSeconds, duration);
  // Log the analytics progress event if it's a new event
  if (currentEvent && currentEvent !== lastProgressEvent) {
    lastProgressEvent = currentEvent; // Update last progress event
    analyticsSendCallback(currentEvent);
  }
};

export default sendBarkerAnalytics;
