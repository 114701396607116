
import {AdTypes} from 'features/adoppler';
import {type MetricsService, gabrielElementalMetrics, gabrielCacheMetrics} from 'shared/api/metrics-service';
import {VAST_FILE_RECEIVED_EVENT} from 'shared/constants';
import {eventEmitter, parseResponse} from 'shared/utils';
import {logger as baseLogger} from 'shared/utils/logger';

import type {WorkerConfig, WorkerParams} from 'types';

import type pino from 'pino';

/**
 * Define default web-worker for fetching Adoppler source
 */
class FetchWorker {
  static SCRIPT = './adoppler.js';
  config: WorkerConfig;
  instance: Worker;
  metrics: MetricsService;
  cacheMetrics: MetricsService;
  logger: pino.Logger;

  /**
   * Make worker instance
   * @param {WorkerConfig} config
   */
  constructor(config: WorkerConfig) {
    this.config = config;
    this.logger = baseLogger.child({tag: `Worker [${this.config.scope}]`});
    this.instance = this.register(this.config.scope);
    this.metrics = gabrielElementalMetrics;
    this.cacheMetrics = gabrielCacheMetrics;

    this.logger.info('registered.');
    this.instance.onerror = (e) => {
      this.logger.error(`Throws an error ${e.toString()}`);
    };

    this.instance.onmessage = (e) => {
      const message = e.data;
      switch (message.action.toLowerCase()) {
        case ('adrequest'):
          this.metrics.emitEvent('Request', [{Name: 'source', Value: 'regular'},
            {Name: 'type', Value: config.adType.toString()}]);
          break;

        case ('adresponseerror'):
        case ('adresponse'):
          this.metrics.emitEvent('Response', [{Name: 'source', Value: 'regular'},
            {Name: 'type', Value: config.adType.toString()},
            {Name: 'code', Value: message.payload.data.statusCode.toString()}]);
          break;

        case ('addtocache'):
          try {
            const response = parseResponse(message.payload.response, this.config.adType);
            response?.filter((r) => r.adType === AdTypes.Video)
              .map((r) => r.adUrls).flat(1).map((vastFile) => {
                eventEmitter.emit(VAST_FILE_RECEIVED_EVENT, {vastFile});
              });
          } catch {
            //
          }
          eventEmitter.emit(`${message.action}-${message.payload.cacheKey}`);
          this.cacheMetrics.emitEvent(message.action, [{Name: 'provider', Value: 'Elemental'},
            {Name: 'namespace', Value: message.payload.namespace.toString()}]);
          break;
        case ('expiredcache'):
          if (message.payload && 'namespace' in message.payload) {
            this.cacheMetrics.emitEvent(message.action, [{Name: 'provider', Value: 'Elemental'},
              {Name: 'namespace', Value: message.payload.namespace.toString()}]);
          }
          break;

        case ('logger'):
          this.logger[message.payload.level as pino.LevelWithSilent](message.payload.message);
          break;
      }
    };
  }

  /**
   * Run worker instance
   * @param {object} params
   */
  run(params: WorkerParams) {
    this.instance.postMessage(JSON.stringify({action: 'config', payload: {
      url: this.config.url,
      cache: this.config.cache,
      scope: this.config.scope,
    } }));
    this.instance.postMessage(JSON.stringify({action: 'body', payload: params}));

    if (!this.config.manual) {
      this.instance.postMessage(JSON.stringify({action: 'run', payload: null}));
    }
  }

  /**
   * Make XHR call manually
   */
  makeCall() {
    this.instance.postMessage(JSON.stringify({action: 'makeCall', payload: null}));
  }

  /**
   * Register new worker for the scope
   * @param {string} scope
   * @return {Worker}
   */
  register(scope: string): Worker {
    return new Worker(FetchWorker.SCRIPT, {
      name: scope,
    });
  }

  /**
   * terminate worker
   */
  terminate() {
    this.instance.terminate();
  }
}

export default FetchWorker;
