import type {ReactNode} from 'react';

import {useRemoteControllerEvent, useSoundMute} from 'components/Ads/TypeVideo/helpers';
import {meteEnvConfig} from 'config';

import {MuteVideoContext} from './MuteVideoContext';

const MuteVideoContextProvider = ({children}: {children: ReactNode}): ReactNode | null => {
  const isAdamVideoUnit = meteEnvConfig.ads.adUnit == 'ADAM_SCREENSAVER_VIDEO_UNIT';

  const {isSoundMuted} = useSoundMute();
  const {toggled} = useRemoteControllerEvent(isAdamVideoUnit);

  return (
    <MuteVideoContext.Provider value={{isSoundMuted, toggled}}>
      {children}
    </MuteVideoContext.Provider>
  );
};

export default MuteVideoContextProvider;
