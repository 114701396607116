import {useState} from 'react';

import {globalShared} from 'shared';

import type {CustomEventPayload} from 'types';

interface Payload {
  action: 'mute' | 'unmute'
}

interface VideoSoundEvent extends CustomEventPayload {
  payload: Payload
}

/**
 * Custom hook to control sound manipulation to mute/unmute video
 * @return {{ muted: boolean }} An object representing the audio state with a 'muted' property.
*/
function useSoundMute(): { isSoundMuted: boolean | null; } {
  const [isSoundMuted, setMuted] = useState<boolean | null>(null);

  globalShared.useCustomEventHandler<VideoSoundEvent>('video', (eventDetail) => {
    if (eventDetail?.payload?.action) {
      setMuted(eventDetail.payload.action == 'mute');
    }
  });

  return {isSoundMuted};
}

export default useSoundMute;
