import React from 'react';

import MuteVideoContextProvider from 'context/mute-video-context/MuteVideoProvider';

const withMuteContext = (FC: React.FC) =>
  function() {
    return (
      <MuteVideoContextProvider>
        <FC />
      </MuteVideoContextProvider>
    );
  };

export default withMuteContext;
