import * as Sentry from '@sentry/react';

import TellyAd from 'components/Ads/Telly';
import {logger as baseLogger} from 'shared/utils/logger';

const logger = baseLogger.child({tag: '[Sentry Error Boundary]'});

const withSentryErrorBoundary = (
  component: JSX.Element,
  {key}: {key: string},
) => (
  function() {
    return (
      <Sentry.ErrorBoundary
        key={key}
        onError={(error: unknown) => {
          logger.error('Sentry ErrorBoundary error', error);
        }}
        fallback={<TellyAd />}
      >
        {component}
      </Sentry.ErrorBoundary>

    );
  })();

export default withSentryErrorBoundary;
