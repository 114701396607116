import {memo, useEffect, useRef} from 'react';

import {playingTimeLeft} from 'shared/api/ad/adoppler/helpers';
import {gabrielAnalyticMetrics, gabrielBarkerMetrics} from 'shared/api/metrics-service';
import {VideoPlayer} from 'shared/components';
import {
  VIDEO_AD_FINISHED_EVENT, VIDEO_BUFFERING_TIMEOUT_MS,
  VIDEO_BEFORE_DURATION_END_THRESHOLD_S, VIDEO_REACHED_PLAYING_TIME_THRESHOLD,
} from 'shared/constants';
import {analytics, triggerCustomEvent, isImpressionShouldBeSent} from 'shared/utils';
import {logger as baseLogger} from 'shared/utils/logger';

import type {ParsedResponse} from 'types';

import sendBarkerAnalytics from './helpers';

const logger = baseLogger.child({tag: '[BarkerVideo Component]'});

type BarkerVideoProps = {
  url: string,
  videoConfig: {
    mute?: boolean,
    autoplay?: number,
  },
  barkerData: ParsedResponse['barkerData']
}

export const BarkerVideo = memo(({url, videoConfig, barkerData}: BarkerVideoProps) => {
  const {channelId, blockId, episodeId} = barkerData;

  const isVideoReadyToPlay = useRef(false);
  const durationRef = useRef(0);
  const componentState = useRef({
    impressionSent: false,
    videoEndThresholdSent: false,
  });

  /**
   * @param {unknown} error
   * Trigger analytic error
   */
  const triggerAnalyticError = (error?: unknown) => {
    if (!componentState.current.impressionSent) {
      const errorMessage = (error as Error)?.message || 'Unknown error';
      logger.warn('Error loading Barker video:', errorMessage);
      analytics.emitAdEvent('Error', {
        ad_error_code: `error`,
        ad_error_log_response: 'error while loading barker',
        ad_error_source: 'elemental',
      });
      gabrielAnalyticMetrics.emitEvent('AdError', [{Name: 'type', Value: 'BarkerChannel'}]);
    }
  };

  // Ported this from Gabriel V1 to detect if an ad is taking too long to buffer.
  // This is temporary until we find a better way to handle it. - Juan.
  useEffect(() => {
    isVideoReadyToPlay.current = false;
    componentState.current = {
      impressionSent: false,
      videoEndThresholdSent: false,
    };
    gabrielAnalyticMetrics.emitEvent('AdRequest', [{Name: 'type', Value: 'BarkerChannel'}]);
    const videoBufferTimeout = setTimeout(() => {
      if (!isVideoReadyToPlay.current) {
        logger.warn(`Video buffering timed out. Forcing VIDEO_AD_FINISHED_EVENT event: [${url}]`);
        triggerAnalyticError();
        triggerCustomEvent(VIDEO_AD_FINISHED_EVENT, {isError: true});
      }
    }, VIDEO_BUFFERING_TIMEOUT_MS);

    return () => clearTimeout(videoBufferTimeout);
  }, [url]);

  return (
    <VideoPlayer
      className='default-video-container'
      url={url}
      playing
      muted={!!videoConfig.mute}
      onStart={() => {
        logger.debug('Barker video started', {url});
        analytics.emitAdEvent('AdBarkerData', {
          channel_id: channelId,
          episode_id: episodeId,
          block_id: blockId,
          eventType: 'start',
        });
        gabrielBarkerMetrics.emitEvent('EpisodeClipPlayback', [{Name: 'position', Value: 'start'}]);
      }}
      onReady={() => {
        isVideoReadyToPlay.current = true;
        logger.debug('Barker video is ready to play', {url});
      }}
      onEnded={() => {
        logger.debug('Barker video ended', {url});
        analytics.emitAdEvent('AdBarkerData', {
          channel_id: channelId,
          episode_id: episodeId,
          block_id: blockId,
          eventType: 'complete',
        });
        gabrielBarkerMetrics.emitEvent('EpisodeClipPlayback', [{Name: 'position', Value: 'finish'}]);
        triggerCustomEvent(VIDEO_AD_FINISHED_EVENT, {isError: false});
      }}
      onDuration={(duration) => {
        durationRef.current = duration;
      }}
      onProgress={(progress) => {
        const playedSeconds = Math.trunc(progress.playedSeconds);
        logger.debug('Video progress', {playedSeconds});
        if (!componentState.current.videoEndThresholdSent
          && playingTimeLeft(playedSeconds, durationRef.current) < VIDEO_BEFORE_DURATION_END_THRESHOLD_S) {
          triggerCustomEvent(VIDEO_REACHED_PLAYING_TIME_THRESHOLD, {});
          componentState.current.videoEndThresholdSent = true;
        }

        sendBarkerAnalytics(playedSeconds, durationRef.current, (eventType) => {
          gabrielBarkerMetrics.emitEvent('EpisodeClipPlayback', [{Name: 'position', Value: eventType}]);
          analytics.emitAdEvent('AdBarkerData', {
            channel_id: channelId,
            episode_id: episodeId,
            block_id: blockId,
            eventType: eventType,
          });

          if (isImpressionShouldBeSent(componentState.current.impressionSent, eventType)) {
            gabrielAnalyticMetrics.emitEvent('AdImpression', [
              {Name: 'type', Value: 'BarkerChannel'},
            ]);
          }
        });
      }}
      onError={(error) => {
        logger.warn('Barker video encountered an error', {url});
        triggerAnalyticError(error);
        triggerCustomEvent(VIDEO_AD_FINISHED_EVENT, {isError: true});
      }}
    />
  );
});
