/* eslint-disable camelcase */
export interface GetAppAlternateParams {
  package: string;
  data_uri: string;
  market_uri: string;
}

export const processedAppAlternate = (params: GetAppAlternateParams): TellyAppObject.Root => {
  const {package: appPackage, data_uri, market_uri} = params;
  return {
    id: appPackage,
    launchers: [
      {
        type: 'ecp',
        metadata: {
          package: appPackage,
          data_uri,
          market_uri,
          supported_displays: [
            'htd',
          ],
        },
      },
    ],
    schema_version: 2,
  };
};
