
import {createPortal} from 'react-dom';

import ReactDOM from 'react-dom/client';

import {meteEnvConfig} from 'config';
import {gabrielLifecycleMetrics} from 'shared/api/metrics-service';
import TellySDKClickEventsDebugger from 'shared/components/TellySDKClickEventsDebugger';
import {TellySDKMessages} from 'shared/components/TellySDKMessages';

import {App} from './app';

import './index.css';

(function() {
  // Some external libraries trying serialize to the JSON
  // window object that now allowed and should be prevented
  if (!window?.toJSON) window.toJSON = () => {};
})();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <>
    <App />
    {/* Emit the event when the application is loaded */}
    {gabrielLifecycleMetrics.emitEvent('AppLoaded')}
    {meteEnvConfig.ads.debug.tellySdkClickEvents && createPortal(<TellySDKClickEventsDebugger />, document.body)}
    {__DEV__ && createPortal(<TellySDKMessages />, document.body)}
  </>,
);
