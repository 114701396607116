import {useContext} from 'react';

import {MuteVideoContext, type MuteContextType} from './MuteVideoContext';

const useMuteVideoContext = (): MuteContextType => {
  const context = useContext(MuteVideoContext);
  if (context === undefined) {
    throw new Error('useMuteContext must be used within an AppContext');
  }

  return context;
};

export default useMuteVideoContext;
