export enum VideoProgressEnum {
  firstQuartile = 0.25,
  midpoint = 0.5,
  thirdQuartile = 0.75,
}

/**
 * Get human-readable format of played video fragment based on played seconds and whole duration
 * @param {number} playedSeconds
 * @param {number} duration
 * @return {string | null} some test
 */
export function videoProgress(playedSeconds: number, duration: number): keyof typeof VideoProgressEnum | null {
  // Define the time points for each progress milestone
  const quarters = [
    // Start trigger only on 'onStart' event
    Math.trunc(duration * VideoProgressEnum.firstQuartile), // First Quartile
    Math.trunc(duration * VideoProgressEnum.midpoint), // Midpoint
    Math.trunc(duration * VideoProgressEnum.thirdQuartile), // Third Quartile
    // Complete trigger only on 'onEnded' event
  ];

  // Define labels for each progress milestone
  const progressLabels: { [key: number]: keyof typeof VideoProgressEnum } = {
    [quarters[0]]: 'firstQuartile', // 25% progress
    [quarters[1]]: 'midpoint', // 50% progress
    [quarters[2]]: 'thirdQuartile', // 75% progress
  };

  // Find the appropriate progress label based on played seconds
  return progressLabels[playedSeconds] || null;
}

/**
 * Check that impression should be sent to the service
 * @param {boolean} impressionSent
 * @param {string} progress
 * @return {boolean}
 */
export const isImpressionShouldBeSent = (impressionSent: boolean, progress: string | null): boolean => {
  return progress === 'midpoint' && !impressionSent;
};
