import {useState} from 'react';

import {globalShared} from 'shared';
import {RC_BUTTON_PRESS_EVENT} from 'shared/constants';

import type {CustomEventPayload} from 'types';

interface Payload {
  button: 'A' | 'B'
}

interface ControlEvent extends CustomEventPayload {
  payload: Payload
}

/**
 * Custom hook to control audio based on custom events with a specific payload structure.
 *
 * @param {boolean} isAdamVideoUnit
 * @return {{ eventTimestamp: number }} Last time event happens
*/
function useRemoteControllerEvent(isAdamVideoUnit: boolean): { toggled: boolean } {
  const [toggled, setToggled] = useState<boolean>(false);

  globalShared.useCustomEventHandler<ControlEvent>(RC_BUTTON_PRESS_EVENT, (eventDetail) => {
    if (eventDetail) {
      if (isAdamVideoUnit && eventDetail.payload.button === 'A') {
        setToggled((val) => !val);
      }
    }
  });

  return {toggled};
}

export default useRemoteControllerEvent;
