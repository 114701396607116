const rootUrl = 'https://app.freetelly.com/ads/processBid';
const stagingUrl = `${rootUrl}/87350942`;
const acrStagingUrl = `${rootUrl}/87350942`;
const productionUrl = `${rootUrl}/37176090`;
const acrProductionUrl = `${rootUrl}/37176090`;

const endpoints = {staging: stagingUrl, stagingAcr: acrStagingUrl,
  productionAcr: acrProductionUrl, production: productionUrl};

export {
  endpoints,
};
