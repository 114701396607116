import React from 'react';

import {AdUnitConfigContextProvider} from 'context';

const withContext = (FC: React.FC) =>
  function() {
    return (
      <AdUnitConfigContextProvider>
        <FC />
      </AdUnitConfigContextProvider>
    );
  };

export default withContext;
