import React, {type ReactNode} from 'react';

import {meteEnvConfig} from 'config';
import {useAdUnitConfigContext, useAppContext} from 'context';
import {logger} from 'shared/utils/logger';

import './index.css';

interface AdContainerProps {
  children: React.ReactNode;
}

const AdContainer = ({children}: AdContainerProps) => {
  const {adUnitConfig} = useAdUnitConfigContext();
  const {deviceProperties} = useAppContext();

  const {slot, providers} = adUnitConfig;

  const DESIGN_DIAGONAL_SIZE = Math.sqrt(Math.pow(slot.width, 2) + Math.pow(slot.height, 2));
  const DEVICE_DIAGONAL_SIZE = Math.sqrt(Math.pow(window.screen.width, 2) + Math.pow(window.screen.height, 2));
  const SCALING_FACTOR = deviceProperties?.scaling_factor ?? DEVICE_DIAGONAL_SIZE / DESIGN_DIAGONAL_SIZE;

  logger.info('[Ad Container] Logger', {screen: window.screen, width: window.innerWidth, height: window.innerHeight});

  const isEveFullscreenTemplate = providers.gabriel?.adoppler.template === 'eve-screen-fullscreen';
  const devHighlight = {backgroundColor: __DEV__ ? `rgb(0 0 0 / 88%)` : undefined};

  /**
   * Renders the default ad types based on the template.
   *
   * @return {ReactNode | null} The rendered JSX element for default ad types, or null if not applicable.
   */
  function renderDefaultAdTypes(): ReactNode | null {
    if (!isEveFullscreenTemplate) {
      return (
        <div
          id={meteEnvConfig.ads.adUnit}
          style={{
            transformOrigin: 'top left',
            transform: `scale(${SCALING_FACTOR})`,
            ...slot,
            ...devHighlight,
          }}
        >
          {children}
        </div>
      );
    }
  }

  /**
   * Renders the 'eve-screen-fullscreen' ad types based on the template.
   *
   * @return {ReactNode | null} The rendered JSX element for default ad types, or null if not applicable.
   */
  function renderEveScreenFullscreen(): ReactNode | null {
    if (isEveFullscreenTemplate) {
      return (
        <div
          data-testid='ad-container'
          id={meteEnvConfig.ads.adUnit}
          className='wrapper'
          style={{
            ...slot,
            ...devHighlight,
          }}
        >
          <div
            className={'ad-container'}
            style={{
              transform: `scale(${SCALING_FACTOR})`,
              borderLeft: __DEV__ ? 'solid 2px #676B6E' : undefined,
            }}
          >
            {children}
          </div>
        </div>
      );
    }
  }

  return (
    <>
      {renderDefaultAdTypes()}
      {renderEveScreenFullscreen()}
    </>
  );
};

export default AdContainer;
