import EventEmitter from 'events';

import {v4 as uuid} from 'uuid';

import {meteEnvConfig} from 'config';
import {logger as baseLogger, sendTellySdkAction} from 'shared/utils';

import {validateEvent} from './validation-schema';

import type {AdEventData, AdServer, OperationType, ProcessedData, RequiredFields} from './types';
import type Joi from 'joi';

const logger = baseLogger.child({tag: '[ADS Analytics]'});

type TellySDKActionAnalyticsData = {
  type: ProcessedData<OperationType>['processedField'],
  data: AdEventData<OperationType>
};

/**
 * Represents the Ad Analytics module for handling various ad-related events.
 */
class AdAnalytics {
  /**
   * The EventEmitter instance for handling events.
   * @private
   */
  private _eventEmitter: EventEmitter;

  /**
   * The required fields for each type of ad operation.
   * @private
   */
  private readonly _requiredFields: Record<OperationType, RequiredFields[OperationType]>;

  private ad_request_id: string | undefined = undefined;

  private ad_server: 'gam' | 'elemental' | undefined = undefined;

  private ifa: string | undefined;

  /**
   * Creates an instance of AdAnalytics.
   * Initializes the event emitter, Android SDK event, and required fields.
   */
  constructor() {
    this.ad_request_id = uuid();
    this._requiredFields = this._buildRequiredFields();
    this._eventEmitter = new EventEmitter();
    this._listenToAdEvents();
    this.ifa = undefined;
  }

  /**
   * Resets the ad_request_id by generating a new UUID.
   * @public
   */
  public resetRequestId() {
    this.ad_request_id = uuid();
  }

  /**
   * set some context values to action event.
   * @param {string} ifa - device ifa.
   */
  public setActionsContext({ifa}: {ifa?: string}) {
    this.ifa = ifa;
  }

  /**
   * Emits an ad-related event.
   * @param {string} eventName - The name of the event to emit.
   * @param {AdEventData<T>} data - The data associated with the event.
   */
  public emitAdEvent<T extends OperationType>(eventName: T, data: AdEventData<T>): void {
    if (eventName === 'Request') {
      this.resetRequestId();
      this._eventEmitter.emit(eventName, {...data, ad_placement_id: meteEnvConfig.ads.adUnit});
      this._onInitSetRequiredData((<AdEventData<'Request'>>data).ad_server);
    } else if (eventName === 'AdBarkerData') {
      this._eventEmitter.emit(eventName, {...data, ad_placement_id: meteEnvConfig.ads.adUnit});
    } else {
      this._eventEmitter.emit(eventName, {...data, ad_server: this.ad_server});
    }
  }

  /**
   * Sets the ad-related server 'gam' or 'elemental'.
   * @param {AdServer} adServer - The data associated with the event.
   */
  private _onInitSetRequiredData(adServer: AdServer) {
    this.ad_server = adServer;
  }

  /**
 * Builds the required fields based on the ad server.
 * @private
 * @return {RequiredFields} - The required fields for each type of ad operation.
 */
  private _buildRequiredFields(): RequiredFields {
    const commonFields = ['ad_request_id', 'ifa_id', 'ad_server'];
    const requestFields = [...commonFields, 'ad_placement_id'];
    const impressionFields = [
      ...requestFields, 'bid_request_id', 'advertiser_name', 'creative_id', 'creative_permutation_type',
    ];
    const interactionFields = [...commonFields, 'creative_id', 'creative_permutation_type'];
    const errorFields = [...commonFields, 'ad_error_source', 'ad_error_code', 'ad_error_log_response'];
    const adBarkerDataFields = ['channel_id', 'episode_id', 'block_id', 'ad_placement_id', 'eventType'];

    return {
      Request: requestFields as RequiredFields['Request'],
      Impression: impressionFields as RequiredFields['Impression'],
      Interaction: interactionFields as RequiredFields['Interaction'],
      Error: errorFields as RequiredFields['Error'],
      AdBarkerData: adBarkerDataFields as RequiredFields['AdBarkerData'],
    };
  }

  /**
   * Handles an ad-related event, validates data, processes it, and interacts with the Android SDK.
   * @private
   * @param {OperationType} eventName - The type of ad operation.
   * @param {AdEventData<OperationType>} data - The data associated with the event.
   */
  private _handleAdEvent(eventName: OperationType, data: AdEventData<OperationType>): void {
    let modifiedData = {...data};
    if (eventName !== 'AdBarkerData') {
      modifiedData = {
        ...data,
        ad_request_id: this.ad_request_id,
        ifa_id: this.ifa,
      } as AdEventData<OperationType>;
    }

    const validatedData = this._validateData(modifiedData, eventName);
    const {error, value, warning} = validatedData;

    if (error || warning) {
      const errorParams = {
        ad_error_code: '8000',
        ad_error_log_response: `${error} ${warning}. Generated by "${eventName}"`,
        ad_error_source: this.ad_server!,
        ad_request_id: this.ad_request_id!,
        ifa_id: this.ifa,
      };
      logger.warn(errorParams);
    }

    logger.debug('Processing event data', {eventName, data: value});
    const {data: processedData, processedField} = this._processData(eventName, value);
    logger.debug('Send to Android SDK processed data', {processedField, value});

    // Interact with the Android SDK
    sendTellySdkAction<TellySDKActionAnalyticsData>('ACTION_ANALYTICS', {
      type: processedField,
      data: processedData,
    });
  }

  /**
   * Validates data by checking if it contains all the required fields.
   * @private
   * @param {AdEventData<OperationType>} data - The data to validate.
   * @param {OperationType} eventName - The required fields for the specific ad operation.
   * @return {AdEventData<T>} - Data that been validated.
   */
  private _validateData<T extends OperationType>(
    data: AdEventData<T>,
    eventName: OperationType,
  ): Joi.ValidationResult<AdEventData<T>> {
    const requiredFields = this._requiredFields[eventName];

    if ('ad_unit_id' in data) {
      (requiredFields as string[]).push('ad_unit_id');
    }

    return validateEvent(data, eventName, requiredFields);
  }

  /**
   * Processes data based on the type of ad operation.
   * @private
   * @param {OperationType} eventName - The type of ad operation.
   * @param {AdEventData<T>} data - The data to process.
   * @return {ProcessedData<T>} - The processed data.
   */
  private _processData<T extends OperationType>(
    eventName: OperationType,
    data: AdEventData<T>,
  ): { data: AdEventData<T>; processedField: ProcessedData<T>['processedField']; } {
    const processedFieldLookup: Record<OperationType, ProcessedData<OperationType>['processedField']> = {
      Request: 'adRequest',
      Impression: 'adImpression',
      Interaction: 'adInteraction',
      Error: 'adError',
      AdBarkerData: 'adBarkerData',
    };

    const processedField = processedFieldLookup[eventName];

    return {data, processedField};
  }

  /**
   * Listens to 'ad' events and handles them using the handleAdEvent method.
   * @private
   */
  private _listenToAdEvents(): void {
    Object.keys(this._requiredFields).forEach((eventName) => {
      this._eventEmitter.on(eventName, (data) => this._handleAdEvent(eventName as OperationType, data));
    });
  }
}

/**
 * The instance of the AdAnalytics class.
 */
const analytics = new AdAnalytics();

export default analytics;
