import {memo} from 'react';
import ReactPlayer from 'react-player';

import {AndroidSDKEvent} from 'shared/utils/eventsSdk';
import {logger as baseLogger} from 'shared/utils/logger';

import type {OnProgressProps} from 'react-player/base';
import type {TrackProps} from 'react-player/file';

const sdkEvent = new AndroidSDKEvent();

const logger = baseLogger.child({tag: '[VideoPlayer Component]'});

type VideoPlayerProps = {
  url: string;
  muted: boolean;
  playing: boolean;
  tracks?: TrackProps[];
  className?: string;
  onStart?: () => void;
  onReady?: (player: ReactPlayer) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  onBuffer?: () => void;
  onBufferEnd?: () => void;
  onDuration?: (duration: number) => void;
  onProgress?: (state: OnProgressProps) => void;
  onError?: (error: unknown) => void;
  volume?: number;
}

const VideoPlayer = memo(({
  url,
  muted,
  playing,
  tracks,
  onStart,
  onReady,
  onPlay,
  onPause,
  onEnded,
  onBuffer,
  onBufferEnd,
  onDuration,
  onProgress,
  onError,
  volume,
  className,
}: VideoPlayerProps) => {
  return (
    <ReactPlayer
      width="100%"
      height="100%"
      volume={volume || 0.5}
      url={url}
      controls={__DEV__}
      muted={muted}
      playing={playing}
      className={className}
      onStart={() => {
        logger.debug(`Got onStart event: [${url}]`);
        onStart?.();
      }}
      onReady={(player) => {
        logger.debug(`Got onReady event: [${url}]`);
        onReady?.(player);
      }}
      onPlay={() => {
        logger.debug(`Got onPlay event: [${url}]`);
        onPlay?.();
      }}
      onPause={() => {
        logger.debug(`Got onPause event: [${url}]`);
        onPause?.();
      }}
      onEnded={() => {
        logger.debug(`Got onEnded event: [${url}]`);
        onEnded?.();
      }}
      onBuffer={() => {
        logger.debug(`Got onBuffer event: [${url}]`);
        onBuffer?.();
      }}
      onBufferEnd={() => {
        logger.debug(`Got onBufferEnd event: [${url}]`);
        onBufferEnd?.();
      }}
      onDuration={(duration) => {
        logger.debug(`Got onDuration event: [${url}]`, {duration});
        onDuration?.(duration);
      }}
      onProgress={(progress) => {
        const playedSeconds = Math.trunc(progress.playedSeconds);

        if (playedSeconds % 2 === 0) {
          // logger.debug(`Got onProgress event: [${url}]`, {playedSeconds});
          sdkEvent.sendHeartbeatWithInternetSpeedMonitoring();
        }

        onProgress?.(progress);
      }}
      onError={(error) => {
        logger.warn(`Got onError event: [${url}]`, {error});
        onError?.(error);
      }}
      config={{
        file: {
          attributes: {
            poster: '/load-video.png',
          },
          tracks: tracks || [],
        },
      }}
    />
  );
});

export default VideoPlayer;
