
import {meteEnvConfig} from 'config';
import {GPT_NAMED_SIZE} from 'shared/constants';

import type {AdConfig, DeviceInfo} from 'types';

import type {FreestarParams} from 'shared/utils/freestar-ads/types';
import type {NamedSize} from 'types/google-ad';

/**
 * Find the key in an object by its associated value.
 * @param {string | number[] | null} renderedSize
 *
 * @return {string | undefined} The key associated with the given value, or undefined if not found.
 */
export function getKeyByValue(renderedSize: string | number[] | null): keyof NamedSize | undefined {
  return Object.keys(GPT_NAMED_SIZE).find((key) => {
    const objectValue = GPT_NAMED_SIZE[key as keyof NamedSize];
    if (Array.isArray(objectValue) && Array.isArray(renderedSize)) {
      return (
        objectValue.length === renderedSize.length && objectValue.every((el, index) => el === renderedSize[index])
      );
    }
    return objectValue === renderedSize;
  }) as keyof NamedSize;
}

/**
 * Generates and returns the Google configuration data required for Freestar ads.
 *
 * @param {AdConfig} adUnitConfig - The configuration object for the ad unit.
 * @param {DeviceInfo} deviceProperties - The properties of the device on which the ad will be displayed.
 *
 * @return {FreestarParams} - The parameters needed for initializing and managing Freestar ads.
 *
 * @typedef {Object} AdConfig - The configuration object for the ad unit.
 * @property {Object} providers - The providers configuration.
 * @property {Object} providers.gabriel - The Gabriel provider configuration.
 * @property {Object} providers.gabriel.googleTagManager - The Google Tag Manager configuration.
 *
 * @typedef {Object} DeviceInfo - The properties of the device.
 * @property {Object} ad_info - The advertising information of the device.
 * @property {string} ad_info.ifa - The identifier for advertisers.
 * @property {boolean} ad_info.lmt - The limit ad tracking flag.
 * @property {string} ad_info.ifa_type - The type of identifier for advertisers.
 * @property {Object} user_identifiers - The user identifiers.
 * @property {string} user_identifiers.uid2 - The hashed email of the user.
 * @property {boolean} coppa - The flag indicating if the content is child-directed.
 *
 * @typedef {Object} FreestarParams - The parameters needed for Freestar ads.
 * @property {boolean} eagerLoading - The flag indicating if ads should be eagerly loaded.
 * @property {string} hashedEmail - The hashed email of the user.
 * @property {boolean} coppa - The flag indicating if the content is child-directed.
 * @property {DeviceInfo} deviceProperties - The properties of the device.
 * @property {[[number]]} slotSizes - The sizes of the ad slots.
 * @property {string[]} scriptURLs - The script URLs for Freestar.
 * @property {Object[]} slots - The ad slots configuration.
 * @property {string} slots.placementName - The name of the ad placement.
 * @property {string} slots.slotId - The ID of the ad slot.
 * @property {Object[]} slots.targeting - The targeting parameters for the ad slot.
 * @property {string} slots.targeting.key - The key of the targeting parameter.
 * @property {string|string[]} slots.targeting.value - The value of the targeting parameter.
 */
export function getGoogleConfigData(
  adUnitConfig: AdConfig, deviceProperties: DeviceInfo,
): FreestarParams {
  const googleTagManager = adUnitConfig.providers?.gabriel.googleTagManager;
  const {targeting} = googleTagManager ?? meteEnvConfig.ads.gpt;
  const eagerLoading = googleTagManager?.eagerLoading ?? meteEnvConfig.ads.gpt.eagerLoading;
  const coppa = deviceProperties?.coppa;

  const targetingProfiles = targeting && Object.keys(targeting).map((key)=> {
    return {key, value: targeting[key]};
  });

  const [v83Url, v120Url] = googleTagManager?.scriptURLs ?? [];

  return {
    eagerLoading,
    hashedEmail: deviceProperties?.user_identifiers?.uid2,
    coppa,
    deviceProperties,
    slotSizes: googleTagManager?.slotSizes,
    scriptURLs: [v83Url, v120Url],
    slots: [{
      placementName: googleTagManager?.placementName ?? '',
      slotId: googleTagManager?.slotPlacementId ?? '',
      targeting: [{
        key: 'ifa',
        value: deviceProperties?.ad_info.ifa ?? '',
      },
      ...(targetingProfiles?.length ? targetingProfiles : []),
      ],
    }],
  };
}
