import {useQuery, useQueryClient, type UseQueryResult} from '@tanstack/react-query';

import {useAdUnitConfigContext} from 'context';

import {instance} from './constants';

export interface BarkerBlock {
  type: 'barker',
  url: string,
}

export interface AdBlock {
  type: 'ad'
  duration: number
}
export type ChannelData = {
  episode: string,
  blocks: Array<BarkerBlock | AdBlock>
}

/**
 * Retrieves the previous episode from localStorage.
 * @return {?string} The previous episode if it exists, otherwise null.
 */
export const getPreviousEpisode = (): string | null => {
  return localStorage.getItem('prev_episode');
};

/**
 * Sets the previous episode in localStorage.
 * @param {string} episode - The episode to set as previous.
 */
export const setPreviousEpisode = (episode: string) => {
  localStorage.setItem('prev_episode', episode);
};

/**
 * Retrieves channel data from the server.
 * @param {Object} params - The parameters for fetching channel data.
 * @param {string} params.showId - The ID of the show.
 * @param {?string} params.currentEpisode - The current episode.
 * @return {Promise<ChannelData>} A promise that resolves to the channel data.
 */
export const getChannelData = async (
  {showId, currentEpisode}: { showId?: string, currentEpisode: string | null },
): Promise<ChannelData> => {
  const res = await instance.get('/v1/channel-episode', {params: {showId, currentEpisode} });
  return res.data;
};

export const prevEpisode = getPreviousEpisode();

/**
 * Custom hook to fetch channel data.
 * @return {UseQueryResult<ChannelData>} The result of the channel data query.
 */
export const useChannelData = (): UseQueryResult<ChannelData> => {
  const {adUnitConfig} = useAdUnitConfigContext();
  const showId = adUnitConfig.providers.gabriel.adoppler.config.showId;
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['channel', showId, prevEpisode],
    queryFn: () => getChannelData({showId, currentEpisode: prevEpisode}),
    enabled: !!showId,
    networkMode: 'online',
    initialData: () => queryClient.getQueryData(['channel', showId, prevEpisode]),
  } );
};
