import {meteEnvConfig} from 'config';

const stagingUrl = `https://service-metrics.development.ads.teevee.info`;
const productionUrl = `https://service-metrics.ads.teevee.com`;

const baseUrl = meteEnvConfig.environment === 'production' ? productionUrl : stagingUrl;

export {
  baseUrl,
};
