/* eslint-disable camelcase */
import type {RefObject} from 'react';

import AnimatedQuickActionComponent from './AnimatedQuickActionComponent';

import type {QabElement, ExtResponseAdConfig} from 'features/adoppler';
import type {NativeAd} from 'features/adoppler/types/native';

interface Props {
  adClickTracker: NativeAd.Link | null;
  ext: ExtResponseAdConfig | undefined;
  defaultElement?: QabElement;
  crid?: string | undefined;
  displayControlsBlock?: boolean;
  adNode?: RefObject<HTMLElement>;
  isClickable?: boolean;
}

const V1QuickActionButtonRender = (props: Props) => {
  const {ext, displayControlsBlock, defaultElement = 'default-ad', crid} = props;
  const {
    ad_component_type,
    ad_conversion_trigger,
    ad_conversion_target_display,
  } = ext?.creative_type ?? {};

  // Default ad element
  const defaultAd =
    <div className={'empty-ad extended-ad'}
      style={{
        display: displayControlsBlock ? 'flex' : 'none',
      }}>
      <p>Ad</p>
    </div>;

  // Rendering by defined default element.
  if (!ext?.creative_type) {
    if (defaultElement === 'default-ad') {
      return defaultAd;
    } else if (defaultElement === 'button-b') {
      let screenType = ad_conversion_target_display;
      if (!screenType || !['THEATER', 'SMART'].includes(screenType)) {
        screenType = 'THEATER';
      }

      return displayControlsBlock
      ? <AnimatedQuickActionComponent crid={crid} buttonType={'b_button'} screen={screenType} />
      : null;
    }
    return null;
  }

  if (['full', 'qrcode', 'empty_ad_panel'].includes(ad_component_type!)) {
    // render nothing
    return null;
  }

  if (ad_conversion_trigger !== 'none') {
    const buttonType = ad_conversion_trigger === 'a_button' ? 'a_button' : 'b_button';
    let screenType = ad_conversion_target_display;
    if (!screenType || !['THEATER', 'SMART'].includes(screenType)) {
      screenType = 'THEATER';
    }
    return <AnimatedQuickActionComponent crid={crid} buttonType={buttonType} screen={screenType} />;
  }

  return defaultAd;
};

export default V1QuickActionButtonRender;
