/**
 * Parse version from a string.
 *
 * @param {string} inputString - The input string containing a version identifier.
 * @return {string|null} - The extracted version string or null if not found.
 */
export function parseVersion(inputString?: string): string | null {
  if (!inputString) {
    return null;
  }

  const versionRegex = /\b(\d+\.\d+\.\d+(-\d+)?)\b/; // Regular expression to match a version string

  const match = inputString.match(versionRegex);

  return match ? match[1] : null;
}
