import type {AdopplerCache, AdopplerCacheItem} from 'types';

import type {AdTypes} from 'features/adoppler';

/**
 * Get cache params based on adType
 * @param {AdTypes} adType
 * @param {AdopplerCache | null} adUnitCacheConfig
 * @param {AdopplerCache} defaultCacheConfig
 * @return {AdopplerCacheItem}
 */
export const getCacheConfig = (adType: AdTypes, adUnitCacheConfig: AdopplerCache | null,
  defaultCacheConfig: AdopplerCache): AdopplerCacheItem => {
  const cacheKey = `adoppler-${adType.toString().toLowerCase()}`;
  let cacheConfig = defaultCacheConfig[cacheKey] ?? defaultCacheConfig['default'];
  if (adUnitCacheConfig && (adUnitCacheConfig['default'] || adUnitCacheConfig[cacheKey])) {
    cacheConfig = adUnitCacheConfig[cacheKey] ?? adUnitCacheConfig['default'];
  }

  return cacheConfig;
};
