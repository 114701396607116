import React, {Suspense} from 'react';

const withSuspense = <P extends object>(LazyComponent: React.ComponentType<P>) => {
  return (props: P = {} as P) => (
    <Suspense>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default withSuspense;
