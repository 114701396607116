
import {useEffect} from 'react';

import {AndroidSDKEvent} from 'shared/utils/eventsSdk';

/**
 * Hook to send a heartbeat event periodically when a referenced element is mounted.
 *
 * @param {boolean} show - if ad on the screen
 * @param {string} componentName - debug info to understand what component is shown
 */
export function useHeartBeat(show: boolean, componentName?: string) {
  useEffect(() => {
    const androidSDKEvent = new AndroidSDKEvent();
    const interval = setInterval(() => {
      if (show) {
        // logger.debug('Send HeartBeat for', componentName);
        // Element is in the DOM
        androidSDKEvent.sendHeartbeat();
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [show, componentName]);
}
