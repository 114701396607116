export const appListStub: TellyAppObject.Root[] = [
  {
    id: 'com.teevee.gettheball',
    names:
        {
          en: 'Get the Ball',
          fr: 'Get the Ball',
          sp: 'Get the Ball',
        },
    alt_names:
        [
          'Git the ball',
          'Got the ball',
        ],
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.teevee.quark',
                  activity: 'com.teevee.quark.Quark',
                  supported_displays:
                    [
                      'shd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      templateId: 'SmartScreen_App_With_Ad',
                    },
                  data_uri: 'https://telly.highscoregamearcade.com/002',
                  action: 'android.intent.action.VIEW',
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'arcade',
            ],
        },
    backgroundColor: '#AFDEF8',
    iconAsset: 'com_teevee_gettheball',
  },
  {
    id: 'com.teevee.flappybird',
    names:
        {
          en: 'Flappy Bird',
          sp: 'Flappy Bird',
          fr: 'Flappy Bird',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.teevee.quark',
                  activity: 'com.teevee.quark.Quark',
                  supported_displays:
                    [
                      'shd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      templateId: 'SmartScreen_App_With_Ad',
                    },
                  data_uri: 'https://telly.highscoregamearcade.com/001',
                  action: 'android.intent.action.VIEW',
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'arcade',
            ],
        },
    backgroundColor: '#AFDEF8',
    iconAsset: 'com_teevee_flappybird',
  },
  {
    id: 'com.slacker.radio',
    names:
        {
          en: 'LiveOne',
          sp: 'LiveOne',
          fr: 'LiveOne',
        },
    alt_names:
        [
          'Live One',
        ],
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.slacker.radio',
                  activity: 'com.slacker.androidtv.ui.activities.SplashActivity',
                  supported_displays:
                    [
                      'htd',
                      'shd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'music',
            ],
        },
    backgroundColor: '#2CC1A8',
    iconAsset: 'com_slacker_radio',
  },
  {
    id: 'com.teevee.zoom',
    names:
        {
          en: 'Zoom',
          sp: 'Zoom',
          fr: 'Zoom',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.teevee.zoom',
                  activity: 'com.teevee.zoom.ui.MainActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'video_conference',
            ],
        },
    backgroundColor: '#0B5CFF',
    iconAsset: 'com_teevee_zoom',
  },
  {
    id: 'com.teevee.camera',
    names:
        {
          en: 'Camera',
          sp: 'Camera',
          fr: 'Camera',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.teevee.camera',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'video_conference',
            ],
        },
    backgroundColor: '#34A853',
    iconAsset: 'com_teevee_camera',
  },
  {
    id: 'com.teevee.spotify',
    names:
        {
          en: 'Spotify',
          sp: 'Spotify',
          fr: 'Spotify',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.spotify.tv.android',
                  activity: 'com.spotify.tv.android.SpotifyTVActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'music',
            ],
        },
    backgroundColor: '#313033',
    iconAsset: 'com_teevee_spotify',
  },
  {
    id: 'com.teevee.nex',
    names:
        {
          en: 'Active Arcade',
          sp: 'Active Arcade',
          fr: 'Active Arcade',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'team.nex.arcadexp',
                  activity: 'com.google.firebase.MessagingUnityPlayerActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'motion_tracking',
            ],
        },
    backgroundColor: '#CEFF00',
    iconAsset: 'com_teevee_nex',
  },
  {
    id: 'com.playworks.asteroids',
    names:
        {
          en: 'Asteroids',
          sp: 'Asteroids',
          fr: 'Asteroids',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.asteroids',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'arcade',
            ],
        },
    backgroundColor: '#082128',
    iconAsset: 'com_playworks_asteroids',
  },
  {
    id: 'com.playworks.breakout',
    names:
        {
          en: 'Breakout',
          sp: 'Breakout',
          fr: 'Breakout',
        },
    alt_names:
        [
          'Break out',
        ],
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.breakout',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'arcade',
            ],
        },
    backgroundColor: '#252426',
    iconAsset: 'com_playworks_breakout',
  },
  {
    id: 'com.playworks.centipede',
    names:
        {
          en: 'Centipede',
          sp: 'Centipede',
          fr: 'Centipede',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.centipede',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'arcade',
            ],
        },
    backgroundColor: '#252426',
    iconAsset: 'com_playworks_centipede',
  },
  {
    id: 'com.playworks.chess',
    names:
        {
          en: 'Chess',
          sp: 'Chess',
          fr: 'Chess',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.chess',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'strategy',
              'board',
              'arcade',
            ],
        },
    backgroundColor: '#CDBBA0',
    iconAsset: 'com_playworks_chess',
  },
  {
    id: 'com.playworks.crossyroad',
    names:
        {
          en: 'Crossy Road',
          sp: 'Crossy Road',
          fr: 'Crossy Road',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.crossyroad',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'arcade',
            ],
        },
    backgroundColor: '#69CEEC',
    iconAsset: 'com_playworks_crossyroad',
  },
  {
    id: 'com.playworks.dond',
    names:
        {
          en: 'Deal or No Deal',
          sp: 'Deal or No Deal',
          fr: 'Deal or No Deal',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.dond',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'puzzle',
              'arcade',
            ],
        },
    backgroundColor: '#FFEA95',
    iconAsset: 'com_playworks_dond',
  },
  {
    id: 'com.playworks.freegames',
    names:
        {
          en: 'Free Games',
          sp: 'Free Games',
          fr: 'Free Games',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.freegames',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'arcade',
            ],
        },
    backgroundColor: '#275990',
    iconAsset: 'com_playworks_freegames',
  },
  {
    id: 'com.playworks.onevshundred',
    names:
        {
          en: 'One vs Hundred',
          sp: 'One vs Hundred',
          fr: 'One vs Hundred',
        },
    alt_names:
        [
          'One versus Hundred',
        ],
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.onevshundred',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'strategy',
              'arcade',
            ],
        },
    backgroundColor: '#AB2682',
    iconAsset: 'com_playworks_onevshundred',
  },
  {
    id: 'com.playworks.rummikub',
    names:
        {
          en: 'Rummikub',
          sp: 'Rummikub',
          fr: 'Rummikub',
        },
    alt_names:
        [
          'Rummy Cube',
        ],
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.rummikub',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'card',
              'arcade',
            ],
        },
    backgroundColor: '#E50206',
    iconAsset: 'com_playworks_rummikub',
  },
  {
    id: 'com.playworks.tetris',
    names:
        {
          en: 'Tetris',
          sp: 'Tetris',
          fr: 'Tetris',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.tetris',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'arcade',
              'strategy',
            ],
        },
    backgroundColor: '#263B80',
    iconAsset: 'com_playworks_tetris',
  },
  {
    id: 'com.playworks.triviacrack',
    names:
        {
          en: 'Trivia Crack',
          sp: 'Trivia Crack',
          fr: 'Trivia Crack',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.triviacrack',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'trivia',
              'arcade',
            ],
        },
    backgroundColor: '#4D32B9',
    iconAsset: 'com_playworks_triviacrack',
  },
  {
    id: 'com.playworks.wpt',
    names:
        {
          en: 'WPT',
          sp: 'WPT',
          fr: 'WPT',
        },
    alt_names:
        [
          'World Poker Tour',
        ],
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.wpt',
                  activity: '',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'card',
              'multiplayer',
              'arcade',
            ],
        },
    backgroundColor: '#37004C',
    iconAsset: 'com_playworks_wpt',
  },
  {
    id: 'com.vevo.telly',
    names:
        {
          en: 'Vevo',
          sp: 'Vevo',
          fr: 'Vevo',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.vevo.telly',
                  activity: 'com.vevo.telly.MainActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'music',
            ],
        },
    backgroundColor: '#F9F9F9',
    iconAsset: 'com_vevo_telly',
  },
  {
    id: 'com.teevee.tuner',
    names:
        {
          en: 'TV Tuner',
          sp: 'TV Tuner',
          fr: 'TV Tuner',
        },
    alt_names:
        [
          'Tuner',
        ],
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.nes.seilivetv',
                  activity: 'com.nes.seilivetv.ui.activity.MainActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'watch',
            ],
        },
    backgroundColor: '#313033',
    iconAsset: 'com_teevee_tuner',
  },
  {
    id: 'com.teevee.settings.mood_lighting',
    names:
        {
          en: 'Mood Lighting',
          sp: 'Mood Lighting',
          fr: 'Mood Lighting',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.android.tv.settings',
                  activity: 'com.android.tv.settings.telly.TellySettingsActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      telly_screen: 'mood_lighting',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          settings:
            [
              'mood_lighting',
            ],
        },
    backgroundColor: '',
    iconAsset: 'com_teevee_settings_mood_lighting',
  },
  {
    id: 'com.teevee.settings.timers',
    names:
        {
          en: 'Timers',
          sp: 'Timers',
          fr: 'Timers',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.android.tv.settings',
                  activity: 'com.android.tv.settings.telly.TellySettingsActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      telly_screen: 'timers',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          settings:
            [
              'timers',
            ],
        },
    backgroundColor: '',
    iconAsset: 'com_teevee_settings_timers',
  },
  {
    id: 'com.teevee.settings.network',
    names:
        {
          en: 'Network',
          sp: 'Network',
          fr: 'Network',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.android.tv.settings',
                  activity: 'com.android.tv.settings.telly.TellySettingsActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      telly_screen: 'network',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          settings:
            [
              'network',
            ],
        },
    backgroundColor: '',
    iconAsset: 'com_teevee_settings_network',
  },
  {
    id: 'com.teevee.settings.picture',
    names:
        {
          en: 'Picture',
          sp: 'Picture',
          fr: 'Picture',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.android.tv.settings',
                  activity: 'com.android.tv.settings.telly.TellySettingsActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      telly_screen: 'picture',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          settings:
            [
              'picture',
            ],
        },
    backgroundColor: '',
    iconAsset: 'com_teevee_settings_picture',
  },
  {
    id: 'com.teevee.settings.sound',
    names:
        {
          en: 'Sound',
          sp: 'Sound',
          fr: 'Sound',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.android.tv.settings',
                  activity: 'com.android.tv.settings.telly.TellySettingsActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      telly_screen: 'sound',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          settings:
            [
              'sound',
            ],
        },
    backgroundColor: '',
    iconAsset: 'com_teevee_settings_sound',
  },
  {
    id: 'com.teevee.settings.hdmi',
    names:
        {
          en: 'HDMI',
          sp: 'HDMI',
          fr: 'HDMI',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.android.tv.settings',
                  activity: 'com.android.tv.settings.telly.TellySettingsActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      telly_screen: 'hdmi',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          settings:
            [
              'hdmi',
            ],
        },
    backgroundColor: '',
    iconAsset: 'com_teevee_settings_hdmi',
  },
  {
    id: 'com.teevee.settings.remote_control',
    names:
        {
          en: 'Remote Control',
          sp: 'Remote Control',
          fr: 'Remote Control',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.android.tv.settings',
                  activity: 'com.android.tv.settings.telly.TellySettingsActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      telly_screen: 'remote_control',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          settings:
            [
              'remote_control',
            ],
        },
    backgroundColor: '',
    iconAsset: 'com_teevee_settings_remote_control',
  },
  {
    id: 'com.teevee.settings.account',
    names:
        {
          en: 'Account',
          sp: 'Account',
          fr: 'Account',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.android.tv.settings',
                  activity: 'com.android.tv.settings.telly.TellySettingsActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      telly_screen: 'account',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          settings:
            [
              'account',
            ],
        },
    backgroundColor: '',
    iconAsset: 'com_teevee_settings_account',
  },
  {
    id: 'com.teevee.settings.accessibility',
    names:
        {
          en: 'Accessibility',
          sp: 'Accessibility',
          fr: 'Accessibility',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.android.tv.settings',
                  activity: 'com.android.tv.settings.telly.TellySettingsActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      telly_screen: 'accessibility',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          settings:
            [
              'accessibility',
            ],
        },
    backgroundColor: '',
    iconAsset: 'com_teevee_settings_accessibility',
  },
  {
    id: 'com.teevee.settings.help_and_support',
    names:
        {
          en: 'Help & Support',
          sp: 'Help & Support',
          fr: 'Help & Support',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.android.tv.settings',
                  activity: 'com.android.tv.settings.telly.TellySettingsActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                  intent_extras:
                    {
                      telly_screen: 'help_and_support',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          settings:
            [
              'help_and_support',
            ],
        },
    backgroundColor: '',
    iconAsset: 'com_teevee_settings_help_and_support',
  },
  {
    id: 'com.google.android.youtube.tv',
    names:
        {
          en: 'YouTube',
          sp: 'YouTube',
          fr: 'YouTube',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ecp',
            metadata:
                {
                  package: 'com.google.android.youtube.tv',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {
                      icon_source: 'from_ecp',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'watch',
            ],
        },
    backgroundColor: '#FF0000',
    iconAsset: 'http://192.168.1.33:8080/com_google_android_youtube_tv.png',
  },
  {
    id: 'com.google.android.youtube.tvmusic',
    names:
        {
          en: 'YouTube Music',
          sp: 'YouTube Music',
          fr: 'YouTube Music',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ecp',
            metadata:
                {
                  package: 'com.google.android.youtube.tvmusic',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {
                      icon_source: 'from_ecp',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'music',
            ],
        },
    backgroundColor: '#FF0000',
    iconAsset: 'http://192.168.1.33:8080/com_google_android_youtube_tvmusic.png',
  },
  {
    id: 'com.netflix.ninja',
    names:
        {
          en: 'Netflix',
          sp: 'Netflix',
          fr: 'Netflix',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ecp',
            metadata:
                {
                  package: 'com.netflix.ninja',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {
                      icon_source: 'from_ecp',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'watch',
            ],
        },
    backgroundColor: '#EBEFF0',
    iconAsset: 'http://192.168.1.33:8080/com_netflix_ninja.png',
  },
  {
    id: 'com.amazon.amazonvideo.livingroom',
    names:
        {
          en: 'Prime Video',
          sp: 'Prime Video',
          fr: 'Prime Video',
        },
    alt_names:
        [
          'Prime',
          'Amazon Prime',
        ],
    user_accessible: true,
    launchers:
        [
          {
            type: 'ecp',
            metadata:
                {
                  package: 'com.amazon.amazonvideo.livingroom',
                  activity: 'com.amazon.ignition.IgnitionActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {
                      icon_source: 'from_ecp',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'watch',
            ],
        },
    backgroundColor: '#1E2A36',
    iconAsset: 'http://192.168.1.33:8080/com_amazon_amazonvideo_livingroom.png',
  },
  {
    id: 'com.apple.atve.androidtv.appletv',
    names:
        {
          en: 'Apple TV',
          sp: 'Apple TV',
          fr: 'Apple TV',
        },
    alt_names:
        [
          'Apple',
        ],
    user_accessible: true,
    launchers:
        [
          {
            type: 'ecp',
            metadata:
                {
                  package: 'com.apple.atve.androidtv.appletv',
                  activity: 'com.apple.atve.androidtv.appletv.MainActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {
                      icon_source: 'from_ecp',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'watch',
            ],
        },
    backgroundColor: '#FAF9F9',
    iconAsset: 'http://192.168.1.33:8080/com_apple_atve_androidtv_appletv.png',
  },
  {
    id: 'tv.twitch.android.app',
    names:
        {
          en: 'Twitch',
          sp: 'Twitch',
          fr: 'Twitch',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ecp',
            metadata:
                {
                  package: 'tv.twitch.android.app',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {
                      icon_source: 'from_ecp',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'watch',
            ],
        },
    backgroundColor: '',
    iconAsset: 'http://192.168.1.33:8080/tv_twitch_android_app.png',
  },
  {
    id: 'com.gotv.nflgamecenter.us.lite',
    names:
        {
          en: 'NFL',
          sp: 'NFL',
          fr: 'NFL',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ecp',
            metadata:
                {
                  package: 'com.gotv.nflgamecenter.us.lite',
                  activity: 'com.nfl.connected.SplashActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {
                      icon_source: 'from_ecp',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'watch',
            ],
        },
    backgroundColor: '#FFFFFF',
    iconAsset: 'http://192.168.1.33:8080/com_gotv_nflgamecenter_us_lite.png',
  },
  {
    id: 'com.nbaimd.gametime.nba2011',
    names:
        {
          en: 'NBA',
          sp: 'NBA',
          fr: 'NBA',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ecp',
            metadata:
                {
                  package: 'com.nbaimd.gametime.nba2011',
                  activity: 'com.nba.tv.ui.splash.SplashActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {
                      icon_source: 'from_ecp',
                    },
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'watch',
            ],
        },
    backgroundColor: '',
    iconAsset: 'http://192.168.1.33:8080/com_nbaimd_gametime_nba2011.png',
  },
  {
    id: 'com.playworks.wof',
    names:
        {
          en: 'Wheel Of Fortune',
          sp: 'Wheel Of Fortune',
          fr: 'Wheel Of Fortune',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.wof',
                  activity: 'com.playworks.wof.MainActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'arcade',
            ],
        },
    backgroundColor: '#4F2372',
    iconAsset: 'com_playworks_wof',
  },
  {
    id: 'com.playworks.painttheroad',
    names:
        {
          en: 'Paint The Road',
          sp: 'Paint The Road',
          fr: 'Paint The Road',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'com.playworks.painttheroad',
                  activity: 'com.playworks.painttheroad.MainActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          games:
            [
              'arcade',
            ],
        },
    backgroundColor: '#734E17',
    iconAsset: 'com_playworks_painttheroad',
  },
  {
    id: 'co.gofa.gofa_tv_app',
    names:
        {
          en: 'Gofa',
          sp: 'Gofa',
          fr: 'Gofa',
        },
    user_accessible: true,
    launchers:
        [
          {
            type: 'ondevice',
            metadata:
                {
                  package: 'co.gofa.gofa_tv_app',
                  activity: 'co.gofa.gofa_tv_app.MainActivity',
                  supported_displays:
                    [
                      'htd',
                    ],
                  extras:
                    {},
                },
          },
        ],
    schema_version: 2,
    category:
        {
          apps:
            [
              'fitness',
            ],
        },
    backgroundColor: '#FF3702',
    iconAsset: 'co_gofa_gofa_tv_app',
  },
];
