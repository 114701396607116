import type {ReactNode} from 'react';

import {useDeviceProperties} from 'shared/hooks';

import {AppContext} from './AppContext';

const AppContextProvider = ({children}: {children: ReactNode}): ReactNode | null => {
  const {deviceProperties} = useDeviceProperties();

  return (
    <AppContext.Provider value={{deviceProperties}}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
