import * as Sentry from '@sentry/react';

import {logger} from './logger';

/**
 * Sends an event message to the parent component in a React Native WebView.
 *
 * @param {string} message - The event message to be sent to the parent component.
 * @return {void}
 *
 * @example
 * // Sending an event message to the parent component:
 * sendEventToParent('some_event');
 */
export function sendEventToParent(message: string): void {
  try {
    const webview = window.ReactNativeWebView;

    if (!webview) {
      return;
    }

    if (!webview.postMessage || typeof webview.postMessage !== 'function') {
      Sentry.captureMessage('ReactNativeWebView.postMessage is not a function', 'info');
      return;
    }

    webview.postMessage(JSON.stringify({event: message}));
  } catch (error: unknown) {
    Sentry.captureException(error);
  }
}

/**
 * Logger for android sdk event
 * @param {CustomEvent} e - Event we pass to listener
 * @example
 * // to generate new event trigger:
 * window.dispatchEvent(new CustomEvent("android.sdk", {"detail": "some message"}))
 */
export const androidListener = (e: CustomEvent): void => {
  logger.info(`android.sdk event ${JSON.stringify(e.detail)}`);
};

/**
 * Subscribes to an event by adding an event listener for the specified event name.
 *
 * @param {string} eventName - The name of the event to subscribe to.
 * @param {EventListener} listener - The event listener function to be called when the event is triggered.
 *
 * @example
 * // Subscribe to the 'click' event with a listener function.
 * function handleClickEvent(event) {
 *   logger.log('Click event triggered!', event);
 * }
 * subscribe('click', handleClickEvent);
 */
export function subscribe(eventName: string, listener: EventListener) {
  logger.info('Subscribe on event', eventName);
  document.addEventListener(eventName, listener);
}

/**
 * Unsubscribes from an event by removing an event listener for the specified event name.
 *
 * @param {string} eventName - The name of the event to unsubscribe from.
 * @param {EventListener} listener - The event listener function to be removed from the event.
 *
 * @example
 * // Unsubscribe from the 'click' event with a listener function.
 * function handleClickEvent(event) {
 *   logger.log('Click event triggered!', event);
 * }
 * subscribe('click', handleClickEvent);
 * // Some time later, unsubscribe the event:
 * unsubscribe('click', handleClickEvent);
 */
export function unsubscribe(eventName: string, listener: EventListener) {
  logger.info('Unsubscribe on event', eventName);
  document.removeEventListener(eventName, listener);
}

/**
 * Triggers a custom event with the specified event name and optional data.
 *
 * @param {string} eventName - The name of the event to trigger.
 * @param {object} data - Optional data to pass along with the event.
 *
 * @example
 * // Trigger a custom event with additional data.
 * const eventData = { videoFinished: true };
 * triggerEvent('videoComplete', eventData);
 *
 * // Trigger a custom event without additional data.
 * triggerEvent('customEvent');
 */
export function triggerCustomEvent(eventName: string, data: object) {
  logger.debug('Trigger Custom Event', {eventName, data});
  const event = new CustomEvent(eventName, {detail: data});
  document.dispatchEvent(event);
}
