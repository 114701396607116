import {createContext} from 'react';

export interface AdStrategyContextConfigType {
  startLoop: (fetchInterval: number) => void;
  stopLoop: () => void;
  googleCanBePlayed: boolean;
}

export interface AdStrategyContextType {
  startLoop: (fetchInterval: number) => void;
  stopLoop: () => void;
  googleCanBePlayed: boolean;
}

export const AdStrategyContext = createContext<AdStrategyContextType | undefined>(undefined);
