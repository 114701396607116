import EventEmitter from 'events';

/**
 * Custom event name for emitting empty ad events.
 */
export const EMPTY_AD_EVENT = 'empty-ad-event';

/**
 * EventEmitter instance to emit custom events.
 */
export const eventEmitter = new EventEmitter();
export const sendActionTrackerEventEmitter = new EventEmitter();

/**
 * Example usage:
 *
 * Emit empty ad event:
 *
 * eventEmitter.emit(EMPTY_AD_EVENT, 'domain');
 *
 * Listen for empty ad event:
 *
 * eventEmitter.on(EMPTY_AD_EVENT, (currentDomain) => {
 *   // handle empty ad
 * });
 */
