import FetchWorker from 'shared/api/ad/adoppler/worker';

import type {WorkerConfig, WorkerConfigCache, WorkerParams} from 'types';

import type {AdTypes} from 'features/adoppler';

/**
 * Worker manager
 */
export class FetchWorkerManager {
  private pool: FetchWorker[] = [];

  /**
   * Run worker loop
   * @param {Array} items
   */
  constructor(items: {config: WorkerConfig, params: WorkerParams}[]) {
    items.forEach((item) => {
      const config = item.config;
      const worker = this.spawn(config.url, config.scope, config.cache, config.adType, config.manual);
      worker.run(item.params);
      this.pool.push(worker);
    });
  }

  /**
   * Attach new worker in case it not existing yet
   * @param {WorkerConfig} config
   * @param {WorkerParams} params
   */
  attach(config: WorkerConfig, params: WorkerParams) {
    if (!this.hasWorkerRegistered(config.scope)) {
      const worker = this.spawn(config.url, config.scope, config.cache, config.adType, config.manual);
      worker.run(params);
      this.pool.push(worker);
    }
  }

  /**
   * Make XHR call in case we have registered worker
   * @param {string} workerScope
   */
  request(workerScope: string) {
    const worker = this.getWorkerByScope(workerScope);
    if (worker) {
      worker.makeCall();
    }
  }

  /**
   * Get worker by scope if registered
   * @param {string} workerScope
   * @return {FetchWorker | undefined}
   */
  getWorkerByScope(workerScope: string): FetchWorker | undefined {
    if (this.hasWorkerRegistered(workerScope)) {
      return this.pool.find((worker) => worker.config.scope === workerScope);
    }
  }

  /**
   * Check if scope worker exists
   * @param {string} workerScope
   * @return {boolean}
   */
  hasWorkerRegistered(workerScope: string): boolean {
    return this.pool.some((worker) => worker.config.scope === workerScope);
  }

  /**
   * Stop workers
   */
  terminate() {
    this.pool.forEach((worker) => {
      worker.terminate();
    });
  }

  /**
   * Spawn new worker
   * @param {string} url
   * @param {string} scope
   * @param {WorkerConfigCache} cache
   * @param {AdTypes} adType
   * @param {boolean} manual
   * @return {FetchWorker}
   */
  spawn(url: string, scope: string, cache: WorkerConfigCache, adType: AdTypes, manual: boolean = false): FetchWorker {
    return new FetchWorker({url, scope, cache, adType, manual});
  }
}
