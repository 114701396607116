import {useContext} from 'react';

import {AdStrategyContext} from './AdStrategyContext';

import type {AdStrategyContextType} from './AdStrategyContext';

const useAdStrategyContext = (): AdStrategyContextType => {
  const context = useContext(AdStrategyContext);
  if (context === undefined) {
    throw new Error('useAdStrategyContext must be used within an AdStrategyContextProvider');
  }

  return context;
};

export default useAdStrategyContext;
