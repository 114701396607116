import type {AdConfig} from 'types';

import type {NonceManager} from './pal';

type GlobalStorageType = {
    adUnitConfig: AdConfig | null,
    nonceManager: NonceManager | undefined,
}

export const globalStorage: GlobalStorageType = {
  adUnitConfig: null,
  nonceManager: undefined,
};
